import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  CardFooter,
  IconButton,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SearchBar from "../../../Components/Search";
import { AddRoom } from "../../../Dialogs/AddRoom";
import axios from "axios";
import { urlServer } from "../../../Configuration/heroku";

const TABLE_HEAD = ["Name", "Clients", "Staff", "Room Number"];
type Room = {
  _id: number;
  capacity: string | null;
  createdAt: string;
  description: string;
  facilities: {
    name: string;
  };
  name: string;
  roomNumber: string;
  units: string[];
  updatedAt: string;
  users: string[];
  status: string;
};

type CurrentUser = {
  firstName: string;
  lastName: string;
  rooms: Room[];
};
export const UserRooms = ({ currentuser }: { currentuser: CurrentUser }) => {
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const elementsPerPageOptions = [10, 25, 50, 100];
  const [totalPages, setTotalPages] = useState(10);
  const [view, setView] = useState("table");
  const [tableRows, setTableRows] = useState();
  const [openPopover, setOpenPopover] = useState<string | null>(null);
  const [isSelectDialogOpen, setIsSelectDialogOpen] = useState(false);
  const [rooms, setRooms] = useState<Room[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const triggers = (id: string) => ({
    onClick: () => setOpenPopover(id),
    onMouseEnter: () => {
      if (openPopover !== null) {
        setOpenPopover(id);
      }
    },
    onMouseLeave: () => setOpenPopover(null),
  });
  let { facilityId } = useParams();
  const handleSearch = (units: any[]) => {
    setFilteredData(units);
  };
  const getRooms = async () => {
    const rooms: Room[] = [];

    for (const room of currentuser.rooms) {
      if (typeof room === "object") {
        rooms.push(room);
      } else {
        try {
          const response = await axios.get(`${urlServer}/api/room/${room}`);
          rooms.push(response.data);
        } catch (e) {
          console.error(e);
        }
      }
    }
    setRooms(rooms);
  };
  const next = () => {
    if (currentPage === 10) return;
    setCurrentPage(currentPage + 1);
  };

  const prev = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    getRooms();
  }, []);
  return (
    <div className="p-4">
      <Card
        className="h-full w-full"
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <CardHeader
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          floated={false}
          shadow={false}
          className={` rounded-none overflow-visible ${
            view !== "table" ? "pb-40 md:pb-16 lg:pb-8" : ""
          }`}
        >
          <div className="mb-4 flex items-center justify-between gap-8">
            <div>
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="h5"
                color="blue-gray"
              >
                Rooms list
              </Typography>
            </div>
          </div>
          {/* <div className="float-end">
            <div className="w-full md:w-72">
              <SearchBar
                placeholder="Search clients..."
                data={rooms}
                dataKeys={["name", "capacity", "roomNumber"]}
                onSearch={handleSearch}
              />
            </div>
          </div> */}
        </CardHeader>
        <CardBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="overflow-auto px-0"
        >
          <table className="mt-4 w-full min-w-max table-auto">
            <thead>
              <tr>
                {TABLE_HEAD.map((head, index) => (
                  <th
                    key={head}
                    className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50 text-center"
                  >
                    <Typography
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      variant="small"
                      color="blue-gray"
                      className="flex items-center justify-center gap-2 leading-none opacity-70 !font-bold text-gray-900"
                    >
                      {head}
                      {index !== TABLE_HEAD.length - 1 && (
                        <ChevronUpDownIcon
                          strokeWidth={2}
                          className="h-4 w-4"
                        />
                      )}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rooms && rooms.length > 0 ? (
                rooms.map(
                  ({ _id, name, units, users, capacity, roomNumber }) => {
                    const classes =
                      "p-3 border-b border-blue-gray-50 text-center";
                    return (
                      <tr
                        key={_id}
                        className="hover:bg-gray-100 cursor-pointer"
                        onClick={() =>
                          navigate(`/facility/${facilityId}/room/${_id}`)
                        }
                      >
                        <td className={classes}>
                          <Typography
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                            className="font-normal"
                          >
                            {name}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                            className="font-normal"
                          >
                            {units && (
                              <>
                                {capacity ? (
                                  <>
                                    {units.length} / {capacity}
                                  </>
                                ) : (
                                  <>{units.length}</>
                                )}
                              </>
                            )}
                          </Typography>
                        </td>
                        <td className={classes}>
                          {users && (
                            <Typography
                              placeholder={undefined}
                              onPointerEnterCapture={undefined}
                              onPointerLeaveCapture={undefined}
                              className="font-normal"
                            >
                              {users.length}
                            </Typography>
                          )}
                        </td>
                        <td className={classes}>
                          <Typography
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                            className="font-normal"
                          >
                            {roomNumber}
                          </Typography>
                        </td>
                      </tr>
                    );
                  }
                )
              ) : (
                <tr>
                  <td
                    colSpan={TABLE_HEAD.length}
                    className="p-3 border-b border-blue-gray-50"
                  >
                    <Typography
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      className="text-center font-extrabold"
                    >
                      No rooms assigned
                    </Typography>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </CardBody>

        <CardFooter
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="flex items-center justify-between border-t border-blue-gray-50 p-4"
        >
          <section className="container mx-auto py-0 px-4">
            <div className="flex items-end gap-y-8 justify-between">
              <div className="flex flex-col md:flex-row md:items-center gap-3">
                <Typography
                  variant="small"
                  className="text-gray-600 font-bold"
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  Results: <span className="text-gray-900">40</span>
                </Typography>
                <div className="flex flex-col md:flex-row md:items-center gap-2">
                  <Typography
                    variant="small"
                    className="text-gray-600 font-bold"
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  >
                    {view === "table" ? "Rows" : "Cards"} per page:
                  </Typography>
                  <Menu>
                    <MenuHandler>
                      <Button
                        variant="outlined"
                        className="flex items-center gap-2 !border-gray-300"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                      >
                        {rowsPerPage}
                        <ChevronDownIcon strokeWidth={3} className="w-3 h-3" />
                      </Button>
                    </MenuHandler>
                    <MenuList
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                    >
                      {elementsPerPageOptions
                        .filter((option) => option !== rowsPerPage)
                        .map((option) => (
                          <MenuItem
                            key={option}
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                            onClick={() => setRowsPerPage(option)}
                          >
                            {option}
                          </MenuItem>
                        ))}
                    </MenuList>
                  </Menu>
                </div>
              </div>
              <div className="flex gap-4 items-center">
                <IconButton
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="outlined"
                  onClick={prev}
                  disabled={currentPage === 1}
                  className="border-gray-300"
                >
                  <ChevronLeftIcon strokeWidth={3} className="h-3 w-3" />
                </IconButton>
                <Typography
                  variant="small"
                  className="font-bold text-gray-600"
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  Page <strong className="text-gray-900">{currentPage}</strong>{" "}
                  of <strong className="text-gray-900">{totalPages}</strong>
                </Typography>
                <IconButton
                  variant="outlined"
                  onClick={next}
                  disabled={currentPage === totalPages}
                  className="border-gray-300"
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-3" />
                </IconButton>
              </div>
            </div>
          </section>
        </CardFooter>
      </Card>
      <AddRoom
        open={isSelectDialogOpen}
        onClose={() => {
          setIsSelectDialogOpen(false);
          getRooms();
        }}
        user={user}
      />
    </div>
  );
};
