import React, { FunctionComponent, useEffect, useState } from "react";
import { IUser } from "../../Data/user";
import {
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../Configuration/firebase";
import { IMessage, IRoom } from "../../Data/chat";
import TextMsg from "./TextMsg";
import { RoomFooter } from "./RoomFooter";

export const Room: FunctionComponent<IRoom> = (props) => {
  const [messages, setMessages] = useState([]);
  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
    const messagesRef = collection(db, "chatRooms", props.id, "messages");
    const q = query(messagesRef, orderBy("createdAt"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messagesData: any = snapshot.docs.map((doc) => ({
        id: doc.id,
        name: "test name",
        avatar: '"https://picsum.photos/id/1/200',
        ...doc.data(),
      }));
      setMessages(messagesData);
    });

    return () => unsubscribe();
  }, [props.id]);

  useEffect(() => {
    const userInfo = localStorage.getItem("user");
    if (userInfo) {
      const user = JSON.parse(userInfo);
      user.id = user.uid;
      setUser(user);
    }
  }, []);

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-y-auto mb-4 p-4">
        {user && messages.length > 0 ? (
          messages.map((message: IMessage) => {
            return (
              <div key={message.id} className="pb-1">
                {(() => {
                  switch (message.type) {
                    // case "image":
                    //   return <MediaMsg el={message} key={message.id} />;
                    // case "document":
                    //   return <DocMsg el={message} key={message.id} />;
                    // case "link":
                    //   return <LinkMsg el={message} key={message.id} />;
                    // case "reply":
                    //   return <ReplyMsg el={message} key={message.id} />;
                    default:
                      return <TextMsg message={message} user={user} />;
                  }
                })()}
              </div>
            );
          })
        ) : (
          <div>No Message, waiting for you to start the chat.</div>
        )}
      </div>
      <RoomFooter room={props} user={user} />
    </div>
  );
};
