import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Typography,
  CardBody,
  Button,
  Chip,
} from "@material-tailwind/react";
import axios from "axios";
import { urlServer } from "../../../Configuration/heroku";
import { useParams } from "react-router-dom";

export default function Routes() {
  let { facilityId } = useParams();

  const GEOCODING_API_KEY = process.env.REACT_APP_GOOGLE_GEOCODING_API_KEY;

  const [units, setUnits] = useState<any[]>([]);
  const [selectedLatLng, setSelectedLatLng] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const facilityData = localStorage.getItem("facility");
  const parsedFacilityData = facilityData ? JSON.parse(facilityData) : null;

  const getUnitsByFacility = async () => {
    try {
      const response = await axios.get(`${urlServer}/api/units/${facilityId}`);

      const data = response.data.units.map((unit: any) => {
        const savedStatus = localStorage.getItem(`transportation_${unit._id}`);
        if (savedStatus) {
          const { type } = JSON.parse(savedStatus);
          return { ...unit, status: type };
        }

        return { ...unit, status: "idle" };
      });

      setUnits(data);
    } catch (error) {
      console.error("Error fetching units:", error);
    }
  };

  useEffect(() => {
    getUnitsByFacility();
  }, []);

  async function getLatLng(address: string) {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${GEOCODING_API_KEY}`
      );

      if (response.data.results.length > 0) {
        const location = response.data.results[0].geometry.location;
        return { lat: location.lat, lng: location.lng };
      } else {
        console.warn(`No results found for address: ${address}`);
        return null;
      }
    } catch (error) {
      console.error("Error fetching lat/lng for address:", error);
      return null;
    }
  }

  const handleAddressClick = async (unit: any) => {
    const fullAddress = `${unit.address}, ${unit.city}, ${unit.state} ${unit.zipCode}`;
    const coordinates = await getLatLng(fullAddress);

    if (coordinates) {
      setSelectedLatLng(coordinates);
    }
  };

  const handleSubmit = async (unit: any, type: "pickup" | "dropoff") => {
    try {
      const address =
        type === "pickup"
          ? {
              address: unit.address,
              city: unit.city,
              state: unit.state,
              zipCode: unit.zipCode,
            }
          : {
              address: parsedFacilityData?.location.address,
              city: parsedFacilityData?.location.city,
              state: parsedFacilityData?.location.state,
              zipCode: parsedFacilityData?.location.zipCode,
            };

      await axios.post(`${urlServer}/api/transportation/${facilityId}/create`, {
        unitId: unit._id,
        type,
        userId: user._id,
        ...address,
      });

      localStorage.removeItem(`transportation_${unit._id}`);
      localStorage.setItem(
        `transportation_${unit._id}`,
        JSON.stringify({
          unitId: unit._id,
          type,
          timestamp: new Date().toISOString(),
        })
      );

      setUnits((prevUnits) =>
        prevUnits.map((u) => (u._id === unit._id ? { ...u, status: type } : u))
      );
    } catch (error) {
      console.error(`Error creating ${type} transportation:`, error);
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-full">
      <div className="w-full md:w-1/3 overflow-auto p-4">
        <Card
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="h-full w-full border border-gray-300 shadow-sm rounded-lg"
        >
          <CardHeader
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            floated={false}
            shadow={false}
          >
            <div className="mb-8 flex items-center justify-between gap-8">
              <div>
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="h5"
                  color="blue-gray"
                >
                  Routes
                </Typography>
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  color="gray"
                  className="mt-1 font-normal"
                >
                  See information about all routes
                </Typography>
              </div>
            </div>
          </CardHeader>
          <CardBody
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            className="overflow-auto px-4"
          >
            {units.length > 0 ? (
              units.map((unit, index) => (
                <Card
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  key={index}
                  className={`mb-4 p-4 border border-gray-300 rounded-lg shadow-sm cursor-pointer`}
                  onClick={() => handleAddressClick(unit)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex-grow pr-4">
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        variant="h6"
                        color="blue-gray"
                      >
                        {unit.firstName} {unit.lastName}
                      </Typography>
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        color="gray"
                        className="text-sm"
                      >
                        {unit.address}, {unit.city}, {unit.state} {unit.zipCode}
                      </Typography>
                    </div>
                    <div>
                      {" "}
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          const nextType =
                            unit.status === "pickup" ? "dropoff" : "pickup";
                          handleSubmit(unit, nextType);
                        }}
                        className="p-0 mx-2"
                        variant="text"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                      >
                        <Chip
                          className="p-3 h-full text-center"
                          color={unit.status === "pickup" ? "red" : "green"}
                          value={
                            unit.status === "pickup" ? "Drop Off" : "Pick Up"
                          }
                        />
                      </Button>
                    </div>

                    {/* <input
                      type="checkbox"
                      checked={unit.pickedUp}
                      onChange={() => handleCheckboxChange(index)}
                    /> */}
                  </div>
                </Card>
              ))
            ) : (
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                color="gray"
                className="text-center"
              >
                No clients found
              </Typography>
            )}
          </CardBody>
        </Card>
      </div>
      <div className="w-full overflow-auto p-4 ">
        <Card
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="h-full w-full border border-gray-300 shadow-sm rounded-lg"
        >
          <CardBody
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            className="h-full w-full"
          >
            <iframe
              src={`https://www.google.com/maps/embed/v1/place?key=${GEOCODING_API_KEY}&q=${selectedLatLng?.lat},${selectedLatLng?.lng}`}
              width="100%"
              height="100%"
              style={{ border: 0 }}
              loading="lazy"
              title="Routes"
            ></iframe>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
