import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  IconButton,
  Typography,
  DialogFooter,
  Input,
  Textarea,
  Select,
  Option,
  PopoverContent,
  Popover,
  PopoverHandler,
} from "@material-tailwind/react";
import axios from "axios";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import { IUser } from "../Data/user";
import { urlServer } from "../Configuration/heroku";

export const CreateInvoiceDialog = ({ isOpen, onClose }: any) => {
  const [amount, setAmount] = useState(0);
  const [reason, setReason] = useState("");
  const [date, setDate] = useState();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>();
  const [openDatePickerPopover, setOpenDatePickerPopover] =
    useState<boolean>(false);

  const triggersDatePicker = () => ({
    onClick: () => setOpenDatePickerPopover(!openDatePickerPopover),
    onMouseLeave: () => setOpenDatePickerPopover(false),
  });

  const handleCreateInvoice = async (event: React.FormEvent) => {
    event.preventDefault();

    setLoading(true);
    if (selectedCustomerId) {
      try {
        await axios.post(`${urlServer}/api/payment/create-invoice`, {
          customerId: selectedCustomerId, // Use stored customer ID
          amount: amount * 100, // amount in cents
          currency: "usd",
          description: reason,
        });

        onClose();
      } catch (error) {
        console.error("Error creating invoice:", error);
      } finally {
        setLoading(false);
      }
    }
  };
  const getUsers = async () => {
    const facilityData = localStorage.getItem("facility");
    if (facilityData) {
      const facilityId = JSON.parse(facilityData)._id;
      setLoading(true);
      try {
        const response = await axios.get(
          `${urlServer}/api/${facilityId}/users`
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error gettting users:", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error("No facility data found in localStorage.");
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Dialog
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      className="p-4"
      open={isOpen}
      handler={onClose}
    >
      <DialogHeader
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="justify-between pb-0"
      >
        <Typography
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          color="blue-gray"
          className="mb-1 font-bold"
        >
          Create Invoice
        </Typography>
        <IconButton
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          color="gray"
          size="sm"
          variant="text"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            className="h-4 w-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </DialogHeader>
      <DialogBody
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="overflow-y-scroll pt-0"
      >
        <Typography
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          variant="small"
          className="font-normal text-gray-600"
        >
          Create invoice by setting the recipient and price.
        </Typography>
        <div className="flex items-center flex-col md:flex-row gap-4 mt-4">
          <div className="w-full">
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="small"
              color="blue-gray"
              className="mb-1 font-medium"
            >
              Recipient
            </Typography>
            <Select
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              labelProps={{
                className: "hidden",
              }}
              onChange={(customerId) => setSelectedCustomerId(customerId || "")}
              className="border-t-blue-gray-200 aria-[expanded=true]:border-t-primary"
            >
              {users.map((user) => {
                return <Option value={user.customerId}>{user.email}</Option>;
              })}
            </Select>
          </div>
          <div className="w-full">
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-2 font-medium"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              Due Date
            </Typography>
            <Popover placement="bottom">
              <PopoverHandler {...triggersDatePicker()}>
                <Input
                  size="lg"
                  onChange={() => null}
                  placeholder="Select a Date"
                  value={date ? format(date, "PPP") : ""}
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  crossOrigin={undefined}
                />
              </PopoverHandler>
              <PopoverContent
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                <DayPicker
                  mode="single"
                  selected={date}
                  onSelect={setDate as any}
                  showOutsideDays
                  className="border-0"
                  classNames={{
                    caption:
                      "flex justify-center py-2 mb-4 relative items-center",
                    caption_label: "text-sm !font-medium text-gray-900",
                    nav: "flex items-center",
                    nav_button:
                      "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                    nav_button_previous: "absolute left-1.5",
                    nav_button_next: "absolute right-1.5",
                    table: "w-full border-collapse",
                    head_row: "flex !font-medium text-gray-900",
                    head_cell: "m-0.5 w-9 !font-normal text-sm",
                    row: "flex w-full mt-2",
                    cell: "text-gray-600 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                    day: "h-9 w-9 p-0 !font-normal",
                    day_range_end: "day-range-end",
                    day_selected:
                      "rounded-md bg-gray-900 text-white hover:bg-gray-900 hover:text-white focus:bg-gray-900 focus:text-white",
                    day_today: "rounded-md bg-gray-200 text-gray-900",
                    day_outside:
                      "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10",
                    day_disabled: "text-gray-500 opacity-50",
                    day_hidden: "invisible",
                  }}
                  components={{
                    IconLeft: ({ ...props }) => (
                      <ChevronLeftIcon
                        {...props}
                        className="h-4 w-4 stroke-2"
                      />
                    ),
                    IconRight: ({ ...props }) => (
                      <ChevronRightIcon
                        {...props}
                        className="h-4 w-4 stroke-2"
                      />
                    ),
                  }}
                />
              </PopoverContent>
            </Popover>
          </div>
        </div>
        <div className="flex items-center flex-col md:flex-row gap-4 mt-4">
          <div className="w-full">
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="small"
              color="blue-gray"
              className="mb-1 font-medium"
            >
              Name
            </Typography>
            <Input
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              color="gray"
              placeholder="Invoice"
              labelProps={{
                className: "hidden",
              }}
              className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
              crossOrigin={undefined}
            />
          </div>
          <div className="w-full">
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="small"
              color="blue-gray"
              className="mb-1 font-medium"
            >
              Amount (usd)
            </Typography>
            <Input
              type="number"
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              color="gray"
              placeholder="$1,900"
              value={amount}
              onChange={(e) => setAmount(Number(e.target.value))}
              labelProps={{
                className: "hidden",
              }}
              className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
              crossOrigin={undefined}
              icon={<i className="fas fa-dollar-sign" />}
            />
          </div>
        </div>
        <div className="flex mt-4"></div>
        <div>
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="small"
            color="blue-gray"
            className="mb-1 font-medium"
          >
            Reason
          </Typography>
          <Textarea
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            rows={6}
            placeholder="Reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            labelProps={{
              className: "hidden",
            }}
            className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
          />
        </div>
      </DialogBody>
      <DialogFooter
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="gap-2"
      >
        <Button
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          onClick={onClose}
          color="red"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          onClick={handleCreateInvoice}
        >
          {loading ? "Creating..." : "Create"}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
