import React, { PropsWithChildren } from "react";
import { useLanguage } from "../Helpers/LanguageContext";
import { colorizeLastWords } from "../Helpers/Format";

interface CardItem {
  title: string;
  features: string[];
  price: string;
  previousPrice?: string;
  footer?: string;
  url?: string;
  subscriptionType: string;
  buttonLabel?: string;
}

interface PricingTableProps {
  heading: string;
  description: string;
  subHeading: string;
  color: string;
  cards: CardItem[];
}

export const PricingTables: React.FunctionComponent<
  PropsWithChildren<PricingTableProps>
> = ({ heading, color, cards, description, subHeading }) => {
  const { translate } = useLanguage();

  return (
    <div>
      <div className="text-center">
        <h1 className="font-medium tracking-wider">
          {colorizeLastWords(translate(heading), color, 1)}
        </h1>
        <h2 className="text-4xl sm:text-5xl font-black tracking-wide my-5">
          {colorizeLastWords(translate(subHeading), color, 1)}
        </h2>
        <p className="font-medium text-lg mb-5" style={{ color: "#718096" }}>
          {translate(description)}
        </p>
      </div>
      <div className="flex flex-wrap justify-center -mx-2">
        {cards.map((card, index) => (
          <div key={index} className="w-full md:w-1/2 lg:w-1/3 p-2">
            <div
              className="flex flex-col h-full p-4 rounded-xl shadow-xl text-center transition duration-500 hover:scale-105"
              style={{ borderTop: `4px solid ${color}` }}
            >
              <h2 className="text-xl font-bold mb-4">
                {" "}
                {translate(card.title)}
              </h2>
              {/* {card.previousPrice && (
                <p className="text-5xl line-through text-gray-500 mb-3">
                  {" "}
                  {translate(card.previousPrice)}{" "}
                  <p className="text-sm font-bold mb-3"> per student</p>
                </p>
              )} */}
              <p className="text-5xl font-bold mb-3">
                {" "}
                {translate(card.price)}
                <p className="text-sm font-bold mb-3"> per user</p>
              </p>
              <p
                className="text-lg font-bold mb-5 uppercase tracking-widest"
                style={{ color: "#718096" }}
              >
                {translate(card.subscriptionType)}
              </p>
              <hr className="my-5 border-gray-300" />
              <ul className="flex-grow">
                {card.features.map((feature, featureIndex) => (
                  <li
                    key={featureIndex}
                    className="font-medium text-lg mb-5"
                    style={{ color: "#718096" }}
                  >
                    {translate(feature)}
                  </li>
                ))}
              </ul>
              <hr className="my-5 border-gray-300" />
              {card.footer && <p className="mb-4">{translate(card.footer)}</p>}
              <div className="mt-auto">
                {card.buttonLabel && (
                  <button
                    className="rounded px-8 py-3 text-base text-white font-semibold"
                    style={{ backgroundColor: color }}
                  >
                    {translate(card.buttonLabel)}
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
