import React, { useEffect, useState } from "react";
import axios from "axios";
import { useStripe } from "@stripe/react-stripe-js";
import { urlServer } from "../../Configuration/heroku";

interface Invoice {
  id: string;
  amount_due: number;
  currency: string;
  customer_email: string;
  status: string;
}

const InvoicesTable: React.FC = () => {
  const stripe = useStripe();
  const [invoices, setInvoices] = useState<Invoice[]>([]);

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get(`${urlServer}/api/payment/invoices`);

        setInvoices(
          response.data.filter((invoice: any) => invoice.status !== "draft")
        );
      } catch (error) {
        console.error("Error fetching invoices:", error);
      }
    };

    fetchInvoices();
  }, []);

  const handleInvoiceAction = async (invoice: any) => {
    if (invoice.hosted_invoice_url) {
      window.open(invoice.hosted_invoice_url, "_blank"); // Redirect to the Stripe hosted invoice page
    } else {
      alert("Failed to get payment link.");
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr className="w-full bg-gray-100 border-b border-gray-200">
            <th className="py-2 px-4 text-left text-gray-600">Invoice ID</th>
            <th className="py-2 px-4 text-left text-gray-600">Amount Due</th>
            <th className="py-2 px-4 text-left text-gray-600">Currency</th>
            <th className="py-2 px-4 text-left text-gray-600">
              Customer Email
            </th>
            <th className="py-2 px-4 text-left text-gray-600">Status</th>
            <th className="py-2 px-4 text-left text-gray-600">Action</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice) => (
            <tr key={invoice.id} className="border-b border-gray-200">
              <td className="py-2 px-4">{invoice.id}</td>
              <td className="py-2 px-4">{invoice.amount_due / 100}</td>{" "}
              {/* Stripe returns amounts in cents */}
              <td className="py-2 px-4">{invoice.currency.toUpperCase()}</td>
              <td className="py-2 px-4">{invoice.customer_email}</td>
              <td className="py-2 px-4">{invoice.status}</td>
              <td className="py-2 px-4">
                {invoice.status === "open" ? (
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                    onClick={() => handleInvoiceAction(invoice)}
                  >
                    Pay
                  </button>
                ) : (
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                    onClick={() => handleInvoiceAction(invoice)}
                  >
                    View
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InvoicesTable;
