import React, { useState } from "react";
import {
  Chip,
  Typography,
  Card,
  CardBody,
  IconButton,
  Tooltip,
  Input,
  Button,
} from "@material-tailwind/react";
import {
  EllipsisHorizontalIcon,
  UserIcon,
  HomeIcon,
} from "@heroicons/react/24/solid";
import axios from "axios";
import { urlServer } from "../Configuration/heroku";

interface Facility {
  _id: string;
  name: string;
  email: string;
  fax: string;
  phone: string;
  address: string;
  zipCode: string;
  city: string;
  state: string;
  country: string;
}

interface FacilityProfileProps {
  facility: Facility;
}

export const FacilityProfile: React.FC<FacilityProfileProps> = ({
  facility,
}) => {
  const [status, setStatus] = useState<string>("active");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [formData, setFormData] = useState<Facility>(facility);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = async () => {
    try {
      await axios.put(`${urlServer}/api/facility/${facility._id}`, formData);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating facility:", error);
    }
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
      <div className="flex flex-col gap-2">
        <Card
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="h6"
            className="m-3 flex gap-1"
          >
            <HomeIcon className="!w-5 !h-5 " /> Facility Information
          </Typography>
          <CardBody
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <div className="flex flex-col gap-4">
              <div className="flex justify-between">
                <div className="flex items-center gap-3">
                  <img
                    src="https://www.material-tailwind.com/img/avatar1.jpg"
                    alt="avatar"
                    className="w-10 rounded-full"
                  />
                  <div>
                    <Typography
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      color="blue-gray"
                      className="!font-semibold mb-1"
                    >
                      {facility.name}
                    </Typography>
                    <Typography
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      variant="small"
                      className="!font-medium text-gray-600"
                    >
                      {facility.email}
                    </Typography>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <Tooltip
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    content="Edit Facility"
                  >
                    <IconButton
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      variant="text"
                      onClick={() => setIsEditing(!isEditing)}
                    >
                      <EllipsisHorizontalIcon className="h-8 w-8" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                {isEditing ? (
                  <>
                    <Input
                      crossOrigin={undefined}
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      label="Name"
                    />
                    <Input
                      crossOrigin={undefined}
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      label="Email"
                    />
                    <Input
                      crossOrigin={undefined}
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      label="Phone"
                    />
                    <Input
                      crossOrigin={undefined}
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      name="fax"
                      value={formData.fax}
                      onChange={handleChange}
                      label="Fax"
                    />
                    <Input
                      crossOrigin={undefined}
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      label="Address"
                    />
                    <Input
                      crossOrigin={undefined}
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      label="City"
                    />
                    <Input
                      crossOrigin={undefined}
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                      label="State"
                    />
                    <Input
                      crossOrigin={undefined}
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                      label="Country"
                    />
                    <Input
                      crossOrigin={undefined}
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      name="zipCode"
                      value={formData.zipCode}
                      onChange={handleChange}
                      label="Zip Code"
                    />
                    <Button
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </>
                ) : (
                  <>
                    <div>
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        variant="small"
                        className="!font-medium text-gray-600"
                      >
                        Phone
                      </Typography>
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                      >
                        {facility.phone}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        variant="small"
                        className="!font-medium text-gray-600"
                      >
                        Fax
                      </Typography>
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                      >
                        {facility.fax}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        variant="small"
                        className="!font-medium text-gray-600"
                      >
                        Address
                      </Typography>
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                      >
                        {facility.address}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        variant="small"
                        className="!font-medium text-gray-600"
                      >
                        City
                      </Typography>
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                      >
                        {facility.city}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        variant="small"
                        className="!font-medium text-gray-600"
                      >
                        State
                      </Typography>
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                      >
                        {facility.state}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        variant="small"
                        className="!font-medium text-gray-600"
                      >
                        Country
                      </Typography>
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                      >
                        {facility.country}
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        variant="small"
                        className="!font-medium text-gray-600"
                      >
                        Zip Code
                      </Typography>
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                      >
                        {facility.zipCode}
                      </Typography>
                    </div>
                  </>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
