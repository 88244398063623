import { auth } from "../Configuration/firebase";
import { useEffect, useState } from "react";
import { ProfileMenu } from "./ProfileMenu";
import { PowerIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { Select, Typography, Option } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { IUserFacility } from "../Data/user";
import { IFacility } from "../Data/facility";
import axios from "axios";
import { urlServer } from "../Configuration/heroku";
export const Navbar = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const [facility, setFacility] = useState<IFacility | null>(null);

  const signOut = () => {
    localStorage.clear();
    auth.signOut();
    setUser(null);
  };

  const changeFacility = async (facilityId: string) => {
    const facilityResult = await axios.get(
      `${urlServer}/api/facility/${facilityId}`
    );
    const facility = facilityResult.data;
    if (facility) {
      // Save in Cache
      localStorage.setItem("facility", JSON.stringify(facility));
      setFacility(facility);
      navigate(`/facility/${facility._id}/dashboard`);
      window.location.reload();
    }
  };

  const profileMenuItems = [
    {
      label: "My Profile",
      icon: UserCircleIcon,
      onClick: () => {
        navigate(`/facility/${facility?._id}/user/${user._id}`, {
          state: { user1: user },
        });
      },
    },
    {
      label: "Sign Out",
      icon: PowerIcon,
      onClick: () => signOut(),
    },
  ];

  useEffect(() => {
    const userData = localStorage.getItem("user");
    const facilityData = localStorage.getItem("facility");
    if (userData && facilityData) {
      const user = JSON.parse(userData);
      const facility = JSON.parse(facilityData);
      setUser(user);
      setFacility(facility);
    }
  }, []);

  return (
    <div className="flex justify-between items-center p-4">
      <div className="flex items-center">
        <Typography
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          variant="h5"
        >
          {facility?.name || "MyVitalPath"}
        </Typography>
      </div>
      <div className="flex items-center">
        {user ? (
          <div className="flex items-center gap-2">
            {user.facilities.length > 1 && (
              <Select
                label="Select Facility"
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                value={facility?._id || ""}
                onChange={(facilityId) => changeFacility(facilityId || "")}
              >
                {user.facilities.map((facility: IUserFacility) => {
                  return (
                    <Option value={facility.facilityId}>{facility.name}</Option>
                  );
                })}
              </Select>
            )}
            <ProfileMenu
              profileMenuItems={profileMenuItems}
              name={user ? `${user.firstName} ${user.lastName}` : ""}
            />
          </div>
        ) : (
          <button onClick={() => navigate("/login")}>Log In</button>
        )}
      </div>
    </div>
  );
};
