import { useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useState } from "react";
import InvoicesTable from "../../../Components/Tables/InvoicesTable";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  IconButton,
  Input,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Tab,
  Tabs,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  InboxArrowDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import { CreateInvoiceDialog } from "../../../Dialogs/CreateInvoiceDialog";

const TABS = [
  {
    label: "Sent",
    value: "sent",
  },
  {
    label: "Received",
    value: "received",
  },
];

export const InvoicesPage = () => {
  const stripe = useStripe();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const elementsPerPageOptions = [10, 25, 50, 100];
  const [totalPages, setTotalPages] = useState(10);
  const [loading, setLoading] = useState(false);
  const [isCreateInvoiceDialogOpen, setIsCreateInvoiceDialogOpen] =
    useState(false);

  const toggleCreateInvoiceDialog = () => {
    setIsCreateInvoiceDialogOpen(!isCreateInvoiceDialogOpen);
  };

  const next = () => {
    if (currentPage === 10) return;
    setCurrentPage(currentPage + 1);
  };

  const prev = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  };

  return (
    <div className="p-4">
      <Card
        className="h-full w-full"
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <CardHeader
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          floated={false}
          shadow={false}
          className="rounded-none overflow-visible pb-8"
        >
          <div className="mb-8 flex flex-col md:flex-row items-center justify-between gap-8">
            <div>
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="h5"
                color="blue-gray"
                className="text-center md:text-left"
              >
                Invoices list
              </Typography>
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                color="gray"
                className="mt-1 font-normal text-center md:text-left"
              >
                See information about all invoices
              </Typography>
            </div>
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              <Button
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                className="flex items-center gap-3"
                onClick={toggleCreateInvoiceDialog}
                size="sm"
              >
                <InboxArrowDownIcon strokeWidth={2} className="h-4 w-4" />{" "}
                Create Invoice
              </Button>
            </div>
          </div>
          <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
            <Tabs value="sent" className="w-full md:w-max">
              <TabsHeader
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                {TABS.map(({ label, value }) => (
                  <Tab
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    key={value}
                    value={value}
                  >
                    &nbsp;&nbsp;{label}&nbsp;&nbsp;
                  </Tab>
                ))}
              </TabsHeader>
            </Tabs>
            <div className="w-full md:w-72">
              <Input
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                label="Search"
                icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                crossOrigin={undefined}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="overflow-auto px-0"
        >
          <InvoicesTable />
        </CardBody>
        <CardFooter
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="flex items-center justify-between border-t border-blue-gray-50 p-4"
        >
          <section className="container mx-auto py-10 px-4">
            <div className="flex flex-col md:flex-row md:items-end gap-y-8 justify-between">
              <div className="flex flex-col md:flex-row md:items-center gap-3">
                <Typography
                  variant="small"
                  className="text-gray-600 font-bold"
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  Results: <span className="text-gray-900">40</span>
                </Typography>
                <div className="flex flex-col md:flex-row md:items-center gap-2">
                  <Typography
                    variant="small"
                    className="text-gray-600 font-bold"
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  >
                    Rows per page:
                  </Typography>
                  <Menu>
                    <MenuHandler>
                      <Button
                        variant="outlined"
                        className="flex items-center gap-2 !border-gray-300 w-24"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                      >
                        {rowsPerPage}
                        <ChevronDownIcon strokeWidth={3} className="w-3 h-3" />
                      </Button>
                    </MenuHandler>
                    <MenuList
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                    >
                      {elementsPerPageOptions
                        .filter((option) => option !== rowsPerPage)
                        .map((option) => (
                          <MenuItem
                            key={option}
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                            onClick={() => setRowsPerPage(option)}
                          >
                            {option}
                          </MenuItem>
                        ))}
                    </MenuList>
                  </Menu>
                </div>
              </div>
              <div className="flex gap-4 items-center">
                <IconButton
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="outlined"
                  onClick={prev}
                  disabled={currentPage === 1}
                  className="border-gray-300"
                >
                  <ChevronLeftIcon strokeWidth={3} className="h-3 w-3" />
                </IconButton>
                <Typography
                  variant="small"
                  className="font-bold text-gray-600"
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  Page <strong className="text-gray-900">{currentPage}</strong>{" "}
                  of <strong className="text-gray-900">{totalPages}</strong>
                </Typography>
                <IconButton
                  variant="outlined"
                  onClick={next}
                  disabled={currentPage === totalPages}
                  className="border-gray-300"
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-3" />
                </IconButton>
              </div>
            </div>
          </section>
        </CardFooter>
      </Card>
      <CreateInvoiceDialog
        isOpen={isCreateInvoiceDialogOpen}
        onClose={toggleCreateInvoiceDialog}
      />
    </div>
  );
};
