import React, { PropsWithChildren } from "react";
import { useLanguage } from "../Helpers/LanguageContext";
import { colorizeLastWords } from "../Helpers/Format";

interface TwoColsSectionProps {
  color: string;
  subHeading?: string;
  heading: string;
  description: string;
  imageSrc: string;
  videoLabel?: string;
  videoLink?: string;
  buttonLabel?: string;
  inputPlaceHolder?: string;
  buttonInput?: string;
  colorLengthOnHeading?: number;
  reverse?: boolean;
  onButtonClick?: () => void;
}

export const TwoColsSection: React.FunctionComponent<
  PropsWithChildren<TwoColsSectionProps>
> = (props) => {
  const { translate } = useLanguage();

  return (
    <div
      className={`flex ${
        props.reverse
          ? "flex-col-reverse lg:flex-row-reverse"
          : "flex-col lg:flex-row"
      } lg:items-center mx-auto gap-8`}
    >
      <div className="flex flex-col gap-6 lg:w-6/12 lg:pr-8 text-center lg:text-left">
        <div>
          {props.subHeading && (
            <div className="mb-2">
              <span
                className="text-sm font-medium tracking-wider"
                style={{ color: props.color }}
              >
                {translate(props.subHeading)}
              </span>
            </div>
          )}
          <h1 className="text-6xl leading-tight font-black">
            {colorizeLastWords(
              translate(props.heading),
              props.color,
              props.colorLengthOnHeading
            )}
          </h1>
        </div>
        <p className="font-medium text-xl" style={{ color: "#718096" }}>
          {translate(props.description)}
        </p>
        {props.children}
      </div>
      <div className="lg:w-3/5">
        <img className="rounded-2xl w-full" src={props.imageSrc} alt="" />
      </div>
    </div>
  );
};
