export const colorizeLastWords = (
  text: string,
  color: string,
  length?: number
) => {
  const words = text.split(" ");
  if (length) {
    const mainText = words.slice(0, -length).join(" ");
    const lastWords = words.slice(-length).join(" ");
    return (
      <span>
        {mainText} <span style={{ color: color }}>{lastWords}</span>
      </span>
    );
  }
  return text;
};

export const formatPhoneNumber = (number: string) => {
  const phoneNumber = number.toString();
  const areaCode = phoneNumber.slice(0, 3);
  const firstPart = phoneNumber.slice(3, 6);
  const secondPart = phoneNumber.slice(6, 10);
  return `(${areaCode}) ${firstPart}-${secondPart}`;
};
