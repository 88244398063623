import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  CardBody,
  CardFooter,
  IconButton,
} from "@material-tailwind/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import { urlServer } from "../../../Configuration/heroku";
import { IUnit } from "../../../Data/unit";

const TABLE_HEAD = ["Name", "Date Of Birth", "Address"];

interface UnitsTableProps {
  units: IUnit[];
  user: any;
}

export const UnitsTable: React.FC<UnitsTableProps> = ({ units }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [filteredData, setFilteredData] = useState<IUnit[]>(units);
  let { facilityId } = useParams();
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const next = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getUnitsByFacility = async (page: number, rows: number) => {
    try {
      const response = await axios.get(`${urlServer}/api/units/${facilityId}`, {
        params: { page, rowsPerPage: rows },
      });
      const data = response.data;

      setFilteredData(data.units);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("Error fetching units:", error);
    }
  };

  useEffect(() => {
    getUnitsByFacility(currentPage, 10);
  }, [currentPage]);

  return (
    <section>
      <Card
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="h-full w-full"
      >
        {/* <div className="flex justify-between items-center mx-4">
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="h6"
            className="m-3 flex items-center gap-1"
          >
            <UserIcon className="!w-5 !h-5" /> Clients
          </Typography>
        </div> */}
        <CardBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="!p-0"
        >
          <div className="overflow-x-auto">
            <table className="w-full min-w-max table-auto text-left border-collapse">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-b border-gray-300 py-4 !p-6 text-center text-gray-900"
                    >
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        color="blue-gray"
                        variant="small"
                        className="!font-bold"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredData && filteredData.length > 0 ? (
                  filteredData.map((unit) => {
                    const {
                      _id,
                      firstName,
                      lastName,
                      dateOfBirth,
                      caregivers,
                      address,
                      city,
                      state,
                      country,
                    } = unit;
                    const classes =
                      "px-4 py-2 border-b border-gray-300 text-center ";
                    return (
                      <tr
                        key={_id}
                        onClick={() =>
                          navigate(`/facility/${facilityId}/unit/${_id}`, {
                            state: { units },
                          })
                        }
                        className="cursor-pointer hover:bg-gray-100"
                      >
                        <td className={classes}>
                          <Typography
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                            className="font-normal text-center"
                          >
                            {firstName} {lastName}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                            className="font-normal text-center"
                          >
                            {format(new Date(dateOfBirth), "MM/dd/yyyy")}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                            className="font-normal text-center"
                          >
                            {address && city && state ? (
                              <>
                                {address}, {city}, {state}
                              </>
                            ) : (
                              <></>
                            )}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={TABLE_HEAD.length} className="p-4 text-center">
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        className="font-normal text-center"
                      >
                        No clients found.
                      </Typography>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </CardBody>
        <CardFooter
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="flex justify-between items-center"
        >
          <div className="flex gap-4 items-center ml-auto">
            <IconButton
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="outlined"
              onClick={prev}
              disabled={currentPage === 1}
              className="border-gray-300"
            >
              <ChevronLeftIcon strokeWidth={3} className="h-3 w-3" />
            </IconButton>
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="small"
              className="font-bold text-gray-600"
            >
              Page <strong className="text-gray-900">{currentPage}</strong> of{" "}
              <strong className="text-gray-900">{totalPages}</strong>
            </Typography>
            <IconButton
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="outlined"
              onClick={next}
              disabled={currentPage === totalPages}
              className="border-gray-300"
            >
              <ChevronRightIcon strokeWidth={3} className="h-3 w-3" />
            </IconButton>
          </div>
        </CardFooter>
      </Card>
    </section>
  );
};
