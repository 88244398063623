import { Card, Typography } from "@material-tailwind/react";
import React from "react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";

interface ActivityInfoProps {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  text: string;
  value: number;
  primaryColor: string;
  secondaryColor: string;
}

export const ActivityInfo: React.FC<ActivityInfoProps> = ({
  icon: Icon,
  value,
  text,
  primaryColor,
  secondaryColor,
}) => {
  return (
    <Card
      placeholder=""
      onPointerEnterCapture={() => {}}
      onPointerLeaveCapture={() => {}}
      className="w-full h-16 rounded-lg flex flex-row mb-2"
      style={{ backgroundColor: primaryColor }}
    >
      <div
        className="flex items-center rounded-lg"
        style={{ backgroundColor: secondaryColor }}
      >
        <Icon className="h-5 w-5 m-2" />
      </div>
      <div className="flex items-center gap-3 w-full">
        <Typography
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="text-2xl font-semibold ml-2"
        >
          {value}
        </Typography>

        <Typography
          variant="small"
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="text-xs font-semibold"
        >
          {text}
        </Typography>
        <ArrowRightIcon className="h-5 w-5 ml-auto mr-2 " />
      </div>
    </Card>
  );
};

export default ActivityInfo;
