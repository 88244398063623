import { useLanguage } from "../Helpers/LanguageContext";

export const Footer = () => {
  const { translate } = useLanguage();

  return (
    <div className="flex justify-between items-center bg-gray-200 p-8">
      <div className="flex items-center gap-2">
        <img
          src="https://eduguardianicons.s3.amazonaws.com/android-chrome-384x384.png"
          alt="brand"
          className="h-12 w-12"
        />
      </div>
      <div className="text-gray-400 font-semibold">
        @{translate("poweredBy")} MyVitalPath
      </div>
    </div>
  );
};
