import { useEffect, useState } from "react";
import {
  Dialog,
  Input,
  Select,
  Textarea,
  Option,
} from "@material-tailwind/react";
import { urlServer } from "../Configuration/heroku";
import { IUser } from "../Data/user";
import axios from "axios";

export const SendEmailDialog = ({ isOpen, onClose }: any) => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [recipients, seRecipients] = useState<string[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);

  const sendEmail = async () => {
    const data = {
      subject,
      message,
      recipients,
    };
    if (data.subject && data.message && data.recipients?.length > 0)
      try {
        const response = await fetch(`${urlServer}/api/send-email`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        });
        if (response.ok) {
          alert("Announcement sent successfully!");
          onClose();
        } else {
          const error = await response.json();
          alert(`Failed to send: ${error.message}`);
        }
      } catch (err) {
        console.error("Error sending announcement:", err);
      }
  };

  const getUsers = async () => {
    const facilityData = localStorage.getItem("facility");
    if (facilityData) {
      const facilityId = JSON.parse(facilityData)._id;
      try {
        const response = await axios.get(
          `${urlServer}/api/${facilityId}/users`
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error gettting users:", error);
      }
    } else {
      console.error("No facility data found in localStorage.");
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  // sendEmail({
  //   subject: "testing",
  //   message: "message test",
  //   recipients: ["aleusa2013@gmail.com", "marlon.cuna123@gmail.com"],
  // })

  return (
    <Dialog
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      open={isOpen}
      handler={onClose}
    >
      <div className="p-4">
        <h2 className="mb-4 text-lg">Send an email</h2>
        <div className="mb-4">
          <Select
            label="Select User"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            onChange={(email) => seRecipients(email ? [email] : [])}
          >
            {users.map((user) => {
              return <Option value={user.email}>{user.email}</Option>;
            })}
          </Select>
        </div>
        <div className="mb-4">
          <Input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="p-2 border border-gray-300 rounded w-full"
            placeholder="Subject"
            label="Subject"
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            crossOrigin={undefined}
          />
        </div>
        <div className="mb-2">
          <Textarea
            label="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="p-2 border border-gray-300 rounded w-full"
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          ></Textarea>
        </div>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="mr-2 p-2 bg-gray-500 text-white rounded"
          >
            Cancel
          </button>
          <button
            onClick={() => sendEmail()}
            className="p-2 bg-blue-500 text-white rounded"
          >
            Send
          </button>
        </div>
      </div>
    </Dialog>
  );
};
