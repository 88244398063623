import { Outlet, useLocation } from "react-router";
import { Navbar } from "../../../Components/Navbar";
import { Footer } from "../../../Components/Footer";

export const CompanyLayout = () => {
  const location = useLocation();
  return (
    <div className="flex h-screen overflow-hidden">
      <div className="flex flex-col flex-1 overflow-hidden">
        <Navbar />
        <div className="flex-1 overflow-y-auto" style={{ background: "white" }}>
          <Outlet />
        </div>
        {location.pathname !== "/landing" && <Footer />}
      </div>
    </div>
  );
};
