import { FunctionComponent } from "react";
import { useLanguage } from "../Helpers/LanguageContext";
import { colorizeLastWords } from "../Helpers/Format";

interface CardItem {
  imageSrc: string;
  title?: string;
  description: string;
  footer?: string;
  url?: string;
}

interface ThreeColsSectionProps {
  color: string;
  heading: string;
  cards: CardItem[];
  descriptionFontSize?: string;
  descriptionLineHeight?: string;
  colorLengthOnHeading?: number;
}

export const ThreeColsSection: FunctionComponent<ThreeColsSectionProps> = (
  props
) => {
  const { translate } = useLanguage();

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-center text-4xl sm:text-5xl font-black tracking-wide">
        {colorizeLastWords(
          translate(props.heading),
          props.color,
          props.colorLengthOnHeading || 1
        )}
      </h2>
      <div className="flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto lg:mt-14">
        {props.cards.map((card, index) => (
          <div
            className="flex justify-center max-w-xs lg:w-1/3 mt-12 lg:mt-0"
            key={index}
          >
            <div className="flex flex-col items-center w-3/4 gap-2">
              <img
                className="rounded-s w-24 h-24"
                src={card.imageSrc}
                alt={card.title}
              />
              {card.title && (
                <h3 className="text-lg text-center font-bold group-hover:text-primary-500">
                  {translate(card.title)}
                </h3>
              )}
              <p
                className="text-center mt-1 font-medium text-gray-600"
                style={{
                  fontSize: props.descriptionFontSize || "14px",
                  lineHeight: props.descriptionLineHeight || "20px",
                }}
              >
                {translate(card.description)}
              </p>
              {card.footer && (
                <p className="text-center mt-1 font-medium leading-8 text-lg">
                  - {translate(card.footer)}
                </p>
              )}
              <div className="p-2"> </div>
              {/* {card.url && (
                <a
                  href={`/${card.url}`}
                  className="font-bold items-center leading-8"
                  style={{ color: props.color }}
                >
                  {translate("learnMore")} {"->"}
                </a>
              )} */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
