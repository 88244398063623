import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { MainLayout } from "./Layouts/MainLayout/MainLayout";
import { PageNotFoundPage } from "./Main/PageNotFound/PageNotFound";
import { DashboardPage } from "./Main/DashboardPage/DashboardPage";
import { UnitsPage } from "./Main/UnitsPage/UnitsPage";
import { UsersPage } from "./Main/Users/UsersPage";
import { UserPage } from "./Main/Users/UserPage";
import { UnitPage } from "./Main/UnitPage/UnitPage";
import { RoomPage } from "./Main/RoomPage/RoomPage";
import { RoomsPage } from "./Main/RoomsPage/RoomsPage";
import Calendar from "./Main/Calendar/Calendar";
import Facility from "./Admin/Facility";
import Login from "./Main/Login/Login";
import ProtectedRoute from "./ProtectedRoute";
import { AuthProvider } from "../Context/AuthProvider";
import { ChatPage } from "./Main/ChatPage/ChatPage";
import { FacilityPage } from "./Admin/FacilityPage";
import { InvoicesPage } from "./Main/Invoices/InvoicesPage";
import { SignUp } from "./Main/SignUp/SignUp";
import { ForgotPassword } from "./Main/ForgotPassword/ForgotPassword";
import { CompanyLayout } from "./Layouts/CompanyLayout/CompanyLayout";
import { LandingPage } from "./Company/LandingPage/LandingPage";
import { CaregiverUnitsPage } from "./Main/UnitsPage/CaregiverUnitsPage";
import FacilityRoutes from "./Main/Routes/Routes";
import { RoutesPage } from "./Main/Routes/RoutesPage";
const Kernel: React.FC = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/" element={<CompanyLayout />}>
          <Route path="/" element={<Navigate to="/landing" />} />
          <Route path="/landing" element={<LandingPage />} />
        </Route>
        <Route path="/facility" element={<MainLayout />}>
          <Route
            path="/facility/:facilityId/dashboard"
            element={<ProtectedRoute element={DashboardPage} />}
          />
          <Route
            path="/facility/:facilityId/units"
            element={<ProtectedRoute element={UnitsPage} />}
          />
          <Route
            path="/facility/:facilityId/unit/:id"
            element={<ProtectedRoute element={UnitPage} />}
          />
          <Route
            path="/facility/:facilityId/users"
            element={<ProtectedRoute element={UsersPage} />}
          />
          <Route
            path="/facility/:facilityId/user/:id"
            element={<ProtectedRoute element={UserPage} />}
          />
          <Route
            path="/facility/:facilityId/rooms"
            element={<ProtectedRoute element={RoomsPage} />}
          />
          <Route
            path="/facility/:facilityId/room/:id"
            element={<ProtectedRoute element={RoomPage} />}
          />
          <Route
            path="/facility/:facilityId/invoices"
            element={<ProtectedRoute element={InvoicesPage} />}
          />
          <Route
            path="/facility/:facilityId/calendar"
            element={<ProtectedRoute element={Calendar} />}
          />
          <Route
            path="/facility"
            element={<ProtectedRoute element={Facility} />}
          />
          <Route
            path="/facility/:facilityId"
            element={<ProtectedRoute element={FacilityPage} />}
          />
          <Route
            path="/facility/:facilityId/assigned-units"
            element={<ProtectedRoute element={CaregiverUnitsPage} />}
          />
          <Route
            path="/facility/:facilityId/routes"
            element={<ProtectedRoute element={FacilityRoutes} />}
          />
          <Route
            path="/facility/:facilityId/routes-history"
            element={<ProtectedRoute element={RoutesPage} />}
          />

          {/* <Route
            path="/facilities"
            element={<ProtectedRoute element={Facilities} />}
          /> */}
          <Route
            path="/facility/:facilityId/chat"
            element={<ProtectedRoute element={ChatPage} />}
          />
          <Route index element={<ProtectedRoute element={DashboardPage} />} />
        </Route>
        <Route path="*" element={<PageNotFoundPage />} />
      </Routes>
    </AuthProvider>
  );
};

export default Kernel;
