import { useEffect, useState } from "react";
import "tailwindcss/tailwind.css";
import { AddChatRoomDialog } from "../../../Dialogs/AddChatRoomDialog";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../../Configuration/firebase";
import { RoomElement } from "../../../Components/Chat/RoomElement";
import { IRoom } from "../../../Data/chat";
import { Button } from "@material-tailwind/react";
import { PlusIcon } from "@heroicons/react/24/solid";
import { Room } from "../../../Components/Chat/Room";
import LoadingSpinner from "../../../Components/LoadingSpinner";
import axios from "axios";
import { IUser } from "../../../Data/user";
import { useParams } from "react-router-dom";
import { urlServer } from "../../../Configuration/heroku";

export const ChatPage = () => {
  const [rooms, setRooms] = useState<IRoom[]>([]);
  const [selectedRoom, setSelectedRoom] = useState<IRoom | null>(null);
  const [isAddRoomDialogOpen, setIsAddRoomDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<IUser | null>(null);
  const { facilityId } = useParams<{ facilityId: string }>();

  const handleRoomSelect = (room: IRoom) => {
    setSelectedRoom(room);
  };

  const toggleAddRoomDialog = (needsChange: boolean) => {
    setIsAddRoomDialogOpen(!isAddRoomDialogOpen);
    if (needsChange) {
      getChatRooms();
    }
  };

  const getChatRooms = async () => {
    if (user && facilityId) {
      const userResult = await axios.get(`${urlServer}/api/user/${user._id}`);
      localStorage.setItem("user", JSON.stringify(userResult.data));
      const unsubscribe = onSnapshot(
        collection(db, "chatRooms"),
        async (snapshot) => {
          const roomsData: any = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          const filteredRooms = roomsData.filter((room: any) =>
            userResult.data.chatRooms
              .filter((chatRoom: any) => chatRoom.facilityId === facilityId)
              .some((chatRoom: any) => chatRoom.firebaseId === room.id)
          );
          await Promise.all(
            filteredRooms.map(async (room: any) => {
              room.participantsName = [];

              await Promise.all(
                room.participants.map(async (participant: string) => {
                  if (user && participant !== user._id) {
                    try {
                      const userResult = await axios.get(
                        `${urlServer}/api/user/${participant}`
                      );
                      room.participantsName.push(
                        `${userResult.data.firstName} ${userResult.data.lastName}`
                      );
                    } catch (e) {
                      console.error(e);
                    }
                  }
                })
              );
            })
          );
          setRooms(filteredRooms);
          handleRoomSelect(filteredRooms[0]);
          setLoading(false);
        }
      );

      return () => unsubscribe();
    }
  };

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      const user = JSON.parse(userData);
      setUser(user);
    }
  }, []);

  useEffect(() => {
    if (user) {
      getChatRooms();
    }
  }, [user]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex h-full">
      <div className="border-r border-gray-200 p-4 w-40 md:w-80">
        <Button
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="flex justify-center md:justify-between items-center w-full px-0 mb-4"
          variant="text"
          color="blue"
          onClick={() => toggleAddRoomDialog(false)}
        >
          <span className="hidden md:inline">Create New Group</span>
          <PlusIcon className="w-6 h-6" />
        </Button>
        <input
          type="text"
          placeholder="Search rooms..."
          className="mb-4 p-2 border border-gray-300 rounded w-full"
        />
        <div className="flex flex-col space-y-4">
          {rooms.map((room: IRoom) => (
            <RoomElement
              key={room.id}
              name={room.name}
              avatar={`https://ui-avatars.com/api/?name=${
                room.participantsName?.length > 0
                  ? room.participantsName[0]
                  : "No Assigned"
              }&background=random`}
              lastMessage={`To: ${room.participantsName.join(", ")}`}
              time={room.createdAt}
              unread={10}
              online={true}
              onClick={() => handleRoomSelect(room)}
            />
          ))}
        </div>
      </div>
      <div className="w-full" style={{ minWidth: "25rem" }}>
        {selectedRoom ? (
          <Room {...selectedRoom} />
        ) : (
          <p>Select a room to start chatting</p>
        )}
      </div>
      <AddChatRoomDialog
        isOpen={isAddRoomDialogOpen}
        onClose={toggleAddRoomDialog}
      />
    </div>
  );
};
