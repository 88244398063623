import React, { PropsWithChildren } from "react";
import { useLanguage } from "../Helpers/LanguageContext";
import { colorizeLastWords } from "../Helpers/Format";
interface BannerProps {
  heading: string;
  buttonLabel1?: string;
  buttonLabel2?: string;
}
export const Banner: React.FunctionComponent<
  PropsWithChildren<BannerProps>
> = ({ heading, buttonLabel1, buttonLabel2 }) => {
  const { translate } = useLanguage();
  const handleClick = () => {
    const mailtoLink = `mailto:${buttonLabel1}?subject=${encodeURIComponent(
      "Hello My Vital Path Team"
    )}&body=${encodeURIComponent(
      "I hope this message finds you well. I am reaching out to inquire about your services"
    )}`;
    window.location.href = mailtoLink;
  };
  return (
    <div
      className="flex justify-center items-center rounded-lg text-white py-20 px-10 relative overflow-hidden flex-wrap md:flex-no-wrap md:justify-between gap-4"
      style={{ background: "#4299E1" }}
    >
      <div className="max-w-xl">
        <h1 className="text-3xl md:text-4xl font-semibold leading-normal text-center md:text-left">
          {translate(heading)}
        </h1>
      </div>
      <div className="flex space-x-4">
        {buttonLabel1 && (
          <button
            className="font-semibold bg-red-500 text-white py-4 px-8 rounded-full transition duration-300 ease-in-out hover:bg-red-600"
            onClick={handleClick}
          >
            {translate(buttonLabel1)}
          </button>
        )}
        {buttonLabel2 && (
          <button className="font-semibold bg-white text-purple-600 py-2 px-4 rounded-full transition duration-300 ease-in-out hover:bg-purple-100">
            {translate(buttonLabel2)}
          </button>
        )}
      </div>
    </div>
  );
};
