import React, { useEffect, useState } from "react";
import RoutesTable from "../../../Components/Tables/RoutesTable";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  IconButton,
  Input,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
  Select,
  Option,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import { urlServer } from "../../../Configuration/heroku";
import axios from "axios";
import { useParams } from "react-router-dom";
import { IUser } from "../../../Data/user";
export const RoutesPage = () => {
  let { facilityId } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const elementsPerPageOptions = [10, 25, 50, 100];
  const [totalPages, setTotalPages] = useState(10);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedUser, setSelectedUser] = useState("");

  const next = () => {
    if (currentPage === 10) return;
    setCurrentPage(currentPage + 1);
  };

  const prev = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  };
  const getUsers = async () => {
    try {
      const response = await axios.get(`${urlServer}/api/${facilityId}/users`);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching units:", error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="p-4">
      <Card
        className="h-full w-full"
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <CardHeader
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          floated={false}
          shadow={false}
          className="rounded-none overflow-visible pb-8"
        >
          <div className="mb-8 flex flex-col md:flex-row items-center justify-between gap-8">
            <div>
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="h5"
                color="blue-gray"
                className="text-center md:text-left"
              >
                Transportation History
              </Typography>
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                color="gray"
                className="mt-1 font-normal text-center md:text-left"
              >
                See information about all routes
              </Typography>
              {/* TO BE ADDED */}
              {/* <Select
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                name="users"
                labelProps={{
                  className: "hidden",
                }}
                onChange={(id) => setSelectedUser(id || "")} // Update state with the selected user ID
                className="border-t-blue-gray-200 aria-[expanded=true]:border-t-primary"
              >
                {users.map((user: any) => {
                  return (
                    <Option key={user._id} value={user._id}>
                      {user.firstName} {user.lastName}
                    </Option>
                  );
                })}
              </Select> */}
            </div>
            {/* TO BE ADDED */}
            {/* <div className="w-full md:w-72">
              <Input
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                label="Search"
                icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                crossOrigin={undefined}
              />
            </div> */}
          </div>
        </CardHeader>
        <CardBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="overflow-auto px-0"
        >
          <RoutesTable selectedUser={selectedUser} />
        </CardBody>
        <CardFooter
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="flex items-center justify-between border-t border-blue-gray-50 p-4"
        >
          <section className="container mx-auto py-10 px-4">
            <div className="flex flex-col md:flex-row md:items-end gap-y-8 justify-between">
              <div className="flex flex-col md:flex-row md:items-center gap-3">
                <Typography
                  variant="small"
                  className="text-gray-600 font-bold"
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  Results: <span className="text-gray-900">40</span>
                </Typography>
                <div className="flex flex-col md:flex-row md:items-center gap-2">
                  <Typography
                    variant="small"
                    className="text-gray-600 font-bold"
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  >
                    Rows per page:
                  </Typography>
                  <Menu>
                    <MenuHandler>
                      <Button
                        variant="outlined"
                        className="flex items-center gap-2 !border-gray-300 w-24"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                      >
                        {rowsPerPage}
                        <ChevronDownIcon strokeWidth={3} className="w-3 h-3" />
                      </Button>
                    </MenuHandler>
                    <MenuList
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                    >
                      {elementsPerPageOptions
                        .filter((option) => option !== rowsPerPage)
                        .map((option) => (
                          <MenuItem
                            key={option}
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                            onClick={() => setRowsPerPage(option)}
                          >
                            {option}
                          </MenuItem>
                        ))}
                    </MenuList>
                  </Menu>
                </div>
              </div>
              <div className="flex gap-4 items-center">
                <IconButton
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="outlined"
                  onClick={prev}
                  disabled={currentPage === 1}
                  className="border-gray-300"
                >
                  <ChevronLeftIcon strokeWidth={3} className="h-3 w-3" />
                </IconButton>
                <Typography
                  variant="small"
                  className="font-bold text-gray-600"
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  Page <strong className="text-gray-900">{currentPage}</strong>{" "}
                  of <strong className="text-gray-900">{totalPages}</strong>
                </Typography>
                <IconButton
                  variant="outlined"
                  onClick={next}
                  disabled={currentPage === totalPages}
                  className="border-gray-300"
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-3" />
                </IconButton>
              </div>
            </div>
          </section>
        </CardFooter>
      </Card>
    </div>
  );
};
