import { Typography, Input, Button, Alert } from "@material-tailwind/react";
import { auth } from "../../../Configuration/firebase";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { AlertState, AlertType } from "../../../Data/alert";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { urlServer } from "../../../Configuration/heroku";

export function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => setPasswordShown((cur) => !cur);
  const [alert, setAlert] = useState<AlertState>({
    show: false,
    message: "",
    type: "green",
  });

  // const handleGoogleSignIn = async () => {
  //   const provider = new GoogleAuthProvider();
  //   return signInWithPopup(auth, provider)
  //     .then(async (result) => {
  //       const idToken = await result.user?.getIdToken();
  //       // Send token to backend
  //       await axios.post(`${urlServer}/auth/verify`, {
  //         idToken,
  //       });

  //       const user = result.user;
  //       // Create a user document in Firestore
  //       const userRef = doc(db, "users", user.uid);
  //       await setDoc(userRef, {
  //         name: user.displayName,
  //         email: user.email,
  //         avatar: user.photoURL,
  //       });

  //       // Save in Cache
  //       localStorage.setItem("user", JSON.stringify(user));
  //       navigate("/dashboard");
  //     })
  //     .catch((error) => {
  //       console.error("Error signing in with Google:", error);
  //       throw error;
  //     });
  // };

  const handleSignIn = async () => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      if (result) {
        const idToken = await result.user?.getIdToken();
        // Send token to backend
        await axios.post(`${urlServer}/auth/verify`, {
          idToken,
        });

        // Get user in MongoDB
        const userResult = await axios.get(
          `${urlServer}/api/user/firebaseUid/${result.user.uid}`
        );
        const user = userResult.data;
        if (user && user.facilities && user.facilities.length > 0) {
          // Save in Cache
          localStorage.setItem("user", JSON.stringify(user));
          // Get facility in MongoDB (FOR NOW ONLY FIRST ONE)
          const facilityResult = await axios.get(
            `${urlServer}/api/facility/${user.facilities[0].facilityId}`
          );
          const facility = facilityResult.data;
          if (facility) {
            // Save in Cache
            localStorage.setItem("facility", JSON.stringify(facility));
            navigate(`/facility/${facility._id}/dashboard`);
          }
        }
      }
    } catch (error: any) {
      let errorMessage = "An unknown error occurred. Please try again later.";

      // Handle Firebase Authentication errors
      if (error.code) {
        switch (error.code) {
          case "auth/invalid-email":
            errorMessage = "The email address is not valid.";
            break;
          case "auth/user-disabled":
            errorMessage = "This user has been disabled.";
            break;
          case "auth/user-not-found":
            errorMessage = "No user found with this email.";
            break;
          case "auth/wrong-password":
            errorMessage = "Incorrect password.";
            break;
          default:
            errorMessage = "Failed to sign in. Please check your credentials.";
            break;
        }
      } else if (axios.isAxiosError(error)) {
        // Handle errors from backend API
        if (error.response) {
          errorMessage = `Backend error: ${
            error.response.data.message || error.message
          }`;
        } else if (error.request) {
          errorMessage = "No response from server.";
        } else {
          errorMessage = `Request error: ${error.message}`;
        }
      }

      console.error("Error logging in:", error, errorMessage);
      showAlert(errorMessage, "red");
    }
  };

  const showAlert = (message: string, type: AlertType) => {
    setAlert({ show: true, message, type });

    // Hide the alert after 3 seconds
    setTimeout(() => {
      setAlert({ show: false, message: "", type: "green" });
    }, 3000);
  };

  return (
    <section className="grid h-screen items-center lg:grid-cols-2">
      <div className="my-auto p-8 text-center sm:p-10 md:p-20 xl:px-32 xl:py-24">
        <Typography
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          variant="h3"
          color="blue-gray"
          className="mb-2"
        >
          Sign In
        </Typography>
        <Typography
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          color="gray"
          className="mb-16 font-normal"
        >
          Enter your email and password to sign in
        </Typography>
        <form action="#" className="mx-auto max-w-[24rem] text-left">
          <div className="mb-6">
            <label htmlFor="email">
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="small"
                className="mb-2 block font-medium text-gray-900"
              >
                Your Email
              </Typography>
            </label>
            <Input
              crossOrigin={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              id="email"
              color="gray"
              size="lg"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="name@mail.com"
              className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
              labelProps={{ className: "hidden" }}
            />
          </div>
          <div className="mb-6">
            <label htmlFor="password">
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="small"
                className="mb-2 block font-medium text-gray-900"
              >
                Password
              </Typography>
            </label>
            <Input
              crossOrigin={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              id="password"
              color="gray"
              size="lg"
              type={passwordShown ? "text" : "password"}
              icon={
                <i onClick={togglePasswordVisiblity}>
                  {passwordShown ? (
                    <EyeIcon className="h-5 w-5" />
                  ) : (
                    <EyeSlashIcon className="h-5 w-5" />
                  )}
                </i>
              }
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="********"
              className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
              labelProps={{ className: "hidden" }}
            />
          </div>
          <div className="flex flex-wrap items-center justify-between gap-2">
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              as="a"
              href="#"
              color="gray"
              className="font-medium"
              onClick={(e) => {
                e.preventDefault();
                navigate("/forgot-password");
              }}
            >
              Forgot password
            </Typography>
          </div>
          <Button
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            color="gray"
            size="lg"
            className="mt-6"
            fullWidth
            disabled={!email || !password}
            onClick={handleSignIn}
          >
            sign in
          </Button>
          {/* <Button
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            color="white"
            size="lg"
            className="mt-4 flex h-12 items-center justify-center gap-2"
            fullWidth
            onClick={handleGoogleSignIn}
          >
            <img
              src={`https://www.material-tailwind.com/logos/logo-google.png`}
              alt="google"
              className="h-6 w-6"
            />{" "}
            sign in with google
          </Button> */}
        </form>
      </div>
      <img
        src={`https://www.material-tailwind.com/img/bg-sign-in.avif`}
        alt="background image"
        className="hidden h-screen w-full object-cover lg:block"
      />
      {alert.show && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50">
          <Alert
            color={alert.type}
            className="inline-block max-w-xl text-center"
          >
            {alert.message}
          </Alert>
        </div>
      )}
    </section>
  );
}

export default Login;
