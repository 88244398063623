import React from "react";
import ReactDOM from "react-dom/client";
import Kernel from "./Kernel/Kernel";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { LanguageProvider } from "./Helpers/LanguageContext";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./index.css";
import { Capacitor } from '@capacitor/core';

const stripePromise = loadStripe(
  "pk_test_51PcU11RsPEJX6ZIHmSJmBZkRirdCOi7myeG6TM1oq1jY3WbVKZNf9j7sN3CsajlhwdFglDCE1tuOeH1aed3OspXM00yKcS2YdX"
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const applyMobileStyles = () => {
  if (Capacitor.isNativePlatform()) {
    document.body.style.paddingTop = '40px';
  }
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LanguageProvider>
        <Elements stripe={stripePromise}>
          <Kernel />
        </Elements>
      </LanguageProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// Apply styles for mobile devices
applyMobileStyles();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
