import React, { useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Input } from "@material-tailwind/react";
interface SearchBarProps {
  placeholder?: string;
  data: any[];
  dataKeys?: string[];
  onSearch: (data: any[]) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder = "Search...",
  data,
  dataKeys = [],
  onSearch,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    const filteredData = data.filter((item) => {
      return dataKeys.some((key) => {
        const target = item[key];
        return (
          target &&
          target.toString().toLowerCase().includes(value.toLowerCase())
        );
      });
    });
    onSearch(filteredData);
  };

  return (
    <div className="relative w-full">
      <Input
        value={searchTerm}
        onChange={handleSearch}
        placeholder={placeholder}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        label="Search"
        icon={<MagnifyingGlassIcon className="h-5 w-5" />}
        crossOrigin={undefined}
      />
    </div>
  );
};

export default SearchBar;
