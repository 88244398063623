import React, { PropsWithChildren } from "react";
import { useLanguage } from "../Helpers/LanguageContext";
import { colorizeLastWords } from "../Helpers/Format";
import { FaqItem } from "./FaqItem";
interface FaqProps {
  heading: string;
  description: string;
  subHeading: string;
  color: string;
}

export const Faq: React.FunctionComponent<PropsWithChildren<FaqProps>> = ({
  heading,
  subHeading,
  description,
  color,
}) => {
  const { translate } = useLanguage();
  const faqItemsData = [
    {
      id: 1,
      title: "What is the cost of the software?",
      content: (
        <p>
          We offer the most affordable solution on the market, starting at just
          $9.99 per user. For detailed pricing information, check out our
          pricing section.
        </p>
      ),
    },
    {
      id: 1,
      title: "Can caregivers access the software?",
      content: (
        <p>
          Yes! Through our caregiver portal, caregivers can access updates, view
          attendance, receive important announcements, and stay engaged with
          their loved ones progress.
        </p>
      ),
    },
    {
      id: 1,
      title: "Does the software include attendance tracking?",
      content: (
        <p>
          Yes, our system provides automated check-in and check-out features to
          streamline attendance tracking for adult daycare participants and
          maintain accurate records for easy management and oversight.
        </p>
      ),
    },
    {
      id: 1,
      title: "How do Staff members share information with caregivers?",
      content: (
        <p>
          Staff members can easily share updates, announcements, and important
          messages through our Rooms Feeds, which caregivers can access in
          real-time.
        </p>
      ),
    },
    {
      id: 1,
      title: "Does the platform support staff management?",
      content: (
        <p>
          Yes, we provide comprehensive staff management features, allowing you
          to organize your team, track schedules, and manage workloads
          efficiently.
        </p>
      ),
    },
  ];

  return (
    <div>
      <div className="text-center mb-10">
        <h1 className="font-medium tracking-wider">
          {" "}
          {colorizeLastWords(translate(heading), color, 1)}
        </h1>
        <h2 className="text-4xl sm:text-5xl font-black tracking-wide my-5">
          {colorizeLastWords(translate(subHeading), color, 1)}
        </h2>
        <p className="font-medium text-lg" style={{ color: "#718096" }}>
          {translate(description)}
        </p>
      </div>
      <div id="accordion-open" data-accordion="open">
        {faqItemsData.map((item) => (
          <FaqItem
            key={item.id}
            id={item.id}
            title={item.title}
            content={item.content}
          />
        ))}
      </div>
    </div>
  );
};

export default Faq;
