import { FunctionComponent, useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  Card,
  CardBody,
  Textarea,
  Alert,
} from "@material-tailwind/react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { urlServer } from "../../../Configuration/heroku";

interface UserInformationProps {
  currentuser: {
    firstName: string;
    lastName: string;
    email: string;
    dateOfBirth: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
    notes: string;
    status: string;
  };
}

export const UserInformation: FunctionComponent<UserInformationProps> = ({
  currentuser,
}) => {
  let { id } = useParams();

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    notes: "",
  });
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (currentuser) {
      setUser({
        firstName: currentuser.firstName || "",
        lastName: currentuser.lastName || "",
        email: currentuser.email || "",
        dateOfBirth: currentuser.dateOfBirth || "",
        address: currentuser.address || "",
        city: currentuser.city || "",
        state: currentuser.state || "",
        zipCode: currentuser.zipCode || "",
        notes: currentuser.notes || "",
      });
    }
  }, [currentuser]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUserUpdate = async () => {
    try {
      await axios.put(`${urlServer}/api/user/${id}/update-user`, {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        dateOfBirth: user.dateOfBirth,
        address: user.address,
        city: user.city,
        state: user.state,
        zipCode: user.zipCode,
        notes: user.notes,
      });

      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <>
      {open && (
        <Alert className="rounded-none border-l-4 border-[#2ec946] bg-[#2ec946]/10 font-medium text-[#2ec946]">
          Success
        </Alert>
      )}
      <Card
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="w-full"
      >
        <CardBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="flex flex-col"
        >
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="h5"
            color="blue-gray"
          >
            User Information
          </Typography>
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="small"
            className="text-gray-600 font-normal mt-1"
          >
            Update your user information below.
          </Typography>
          <div className="flex flex-col mt-8">
            <div className="mb-6 flex flex-col items-end gap-4 md:flex-row">
              <div className="w-full">
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="small"
                  color="blue-gray"
                  className="mb-2 font-medium"
                >
                  First Name
                </Typography>
                <Input
                  crossOrigin={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  size="lg"
                  placeholder="First Name"
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                  value={user.firstName}
                  name="firstName"
                  onChange={handleChange}
                />
              </div>
              <div className="w-full">
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="small"
                  color="blue-gray"
                  className="mb-2 font-medium"
                >
                  Last Name
                </Typography>
                <Input
                  crossOrigin={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  size="lg"
                  placeholder="Last Name"
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                  value={user.lastName}
                  name="lastName"
                  onChange={handleChange}
                />
              </div>
              <div className="w-full">
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="small"
                  color="blue-gray"
                  className="mb-2 font-medium"
                >
                  Email
                </Typography>
                <Input
                  crossOrigin={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  size="lg"
                  placeholder="Email"
                  labelProps={{
                    className: "hidden",
                  }}
                  disabled
                  className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                  value={user.email}
                  name="email"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-6 flex flex-col items-end gap-4 md:flex-row">
              <div className="w-full">
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="small"
                  color="blue-gray"
                  className="mb-2 font-medium"
                >
                  Date Of Birth
                </Typography>
                <Input
                  type="date"
                  value={moment(user.dateOfBirth).format("YYYY-MM-DD")}
                  className="border p-1 w-full"
                  crossOrigin={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  onChange={handleChange}
                  name="dateOfBirth"
                />
              </div>
              <div className="w-full">
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="small"
                  color="blue-gray"
                  className="mb-2 font-medium"
                >
                  Address
                </Typography>
                <Input
                  crossOrigin={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  size="lg"
                  placeholder="Address"
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                  value={user.address}
                  name="address"
                  onChange={handleChange}
                />
              </div>
              <div className="w-full">
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="small"
                  color="blue-gray"
                  className="mb-2 font-medium"
                >
                  City
                </Typography>
                <Input
                  crossOrigin={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  size="md"
                  placeholder="city"
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                  value={user.city}
                  name="city"
                  onChange={handleChange}
                />
              </div>
              <div className="w-full">
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="small"
                  color="blue-gray"
                  className="mb-2 font-medium"
                >
                  State
                </Typography>
                <Input
                  crossOrigin={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  size="md"
                  placeholder="state"
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                  value={user.state}
                  name="state"
                  onChange={handleChange}
                />
              </div>
              <div className="w-full">
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="small"
                  color="blue-gray"
                  className="mb-2 font-medium"
                >
                  Zip Code
                </Typography>
                <Input
                  crossOrigin={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  size="md"
                  placeholder="Zip Code"
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                  value={user.zipCode}
                  name="zipCode"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-6 flex flex-col items-end gap-4 md:flex-row">
              <div className="w-full">
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="small"
                  color="blue-gray"
                  className="mb-2 font-medium"
                >
                  Notes
                </Typography>
                <Textarea
                  name="notes"
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  rows={6}
                  placeholder="notes"
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                  value={user.notes}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div>
              {" "}
              <Button
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                className="w-32 float-right"
                onClick={handleUserUpdate}
              >
                Update
              </Button>{" "}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default UserInformation;
