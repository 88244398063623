import { useEffect, useState } from "react";
import {
  Dialog,
  Select,
  Typography,
  Option,
  Alert,
} from "@material-tailwind/react";
import { addRoom } from "../Helpers/chatHelper";
import { IUser, IUserFacility } from "../Data/user";
import axios from "axios";
import { useParams } from "react-router-dom";
import { AlertState, AlertType } from "../Data/alert";
import { urlServer } from "../Configuration/heroku";

export const AddChatRoomDialog = ({ isOpen, onClose }: any) => {
  const [roomName, setRoomName] = useState("");
  const [participants, setParticipants] = useState("");
  const [users, setUsers] = useState<IUser[]>([]);
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState(true);
  const { facilityId } = useParams<{ facilityId: string }>();
  const [alert, setAlert] = useState<AlertState>({
    show: false,
    message: "",
    type: "green",
  });

  const handleAddRoom = async () => {
    if (user && facilityId) {
      const participantsArray = participants.split(",").map((id) => id.trim());
      if (roomName.trim() && participants.length > 0) {
        participantsArray.push(user._id);
        await addRoom(roomName, participantsArray, user._id, facilityId);
        setRoomName("");
        setParticipants("");
        onClose(true);
      } else {
        if (!roomName.trim()) {
          showAlert("Chat Room needs a name", "red");
        }
        if (participants.length === 0) {
          showAlert("Chat Room needs a participant", "red");
        }
      }
    }
  };

  const showAlert = (message: string, type: AlertType) => {
    setAlert({ show: true, message, type });

    // Hide the alert after 3 seconds
    setTimeout(() => {
      setAlert({ show: false, message: "", type: "green" });
    }, 3000);
  };

  const getUsers = async () => {
    const facilityData = localStorage.getItem("facility");
    if (facilityData) {
      const facilityId = JSON.parse(facilityData)._id;
      try {
        const response = await axios.get(
          `${urlServer}/api/${facilityId}/users`
        );
        const users = response.data.filter(
          (userData: IUser) => userData._id !== user?._id
        );
        users.map((user: any) => {
          const facility = user?.facilities?.find(
            (facility: IUserFacility) => facility.facilityId === facilityId
          );
          user.role = facility.role;
        });
        setUsers(users);
      } catch (error) {
        console.error("Error gettting users:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      console.error("No facility data found in localStorage.");
    }
  };

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      const user = JSON.parse(userData);
      setUser(user);
    }
  }, []);

  useEffect(() => {
    if (user) {
      getUsers();
    }
  }, [user]);

  return (
    <Dialog
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      open={isOpen}
      handler={() => {
        setRoomName("");
        setParticipants("");
        onClose(false);
      }}
    >
      <div className="p-4">
        <h2 className="mb-4 text-lg">Add a new room</h2>
        <input
          type="text"
          value={roomName}
          onChange={(e) => setRoomName(e.target.value)}
          className="mb-4 p-2 border border-gray-300 rounded w-full"
          placeholder="Room name"
        />
        <div className="w-full mb-4">
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="small"
            color="blue-gray"
            className="mb-1 font-medium"
          >
            Recipient
          </Typography>
          <Select
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            labelProps={{
              className: "hidden",
            }}
            onChange={(id) => setParticipants(id || "")}
            className="border-t-blue-gray-200 aria-[expanded=true]:border-t-primary"
          >
            {users.map((user: any) => {
              return (
                <Option value={user._id}>
                  {user.email} ({user.role})
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="flex justify-end">
          <button
            onClick={() => {
              setRoomName("");
              setParticipants("");
              onClose(false);
            }}
            className="mr-2 p-2 bg-gray-500 text-white rounded"
          >
            Cancel
          </button>
          <button
            onClick={handleAddRoom}
            className="p-2 bg-blue-500 text-white rounded"
          >
            Add
          </button>
        </div>
      </div>
      {alert.show && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50">
          <Alert
            color={alert.type}
            className="inline-block max-w-xl text-center"
          >
            {alert.message}
          </Alert>
        </div>
      )}
    </Dialog>
  );
};
