import { FunctionComponent, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import { IUnit } from "../Data/unit";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
  Chip,
} from "@material-tailwind/react";
import Pagination from "./Pagination";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { IAttendanceLog } from "../Data/attendanceLog";
import { urlServer } from "../Configuration/heroku";

const TABLE_HEAD = ["Type", "Teacher", "Time"];

export const UnitAttendanceLog: FunctionComponent = () => {
  const { id } = useParams();
  const [unit, setUnit] = useState<IUnit | null>(null);
  const [attendanceLogs, setAttendanceLogs] = useState<IAttendanceLog[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const elementsPerPageOptions = [2, 25, 50, 100];
  const [totalPages, setTotalPages] = useState(10);
  const { facilityId } = useParams<{ facilityId: string }>();

  const fetchUnit = async () => {
    try {
      const unitResponse = await axios.get(`${urlServer}/api/unit/${id}`, {
        params: {
          caregivers: true,
          rooms: true,
        },
      });
      const unit = unitResponse.data;
      setUnit(unit);

      const attendanceLogResponse = await axios.get(
        `${urlServer}/api/attendance-log/${facilityId}/unit/${unit?._id}`
      );
      setAttendanceLogs(attendanceLogResponse.data);
    } catch (error) {
      console.error("Error fetching unit data:", error);
    }
  };

  useEffect(() => {
    fetchUnit();
  }, [id]);

  return (
    <div className="w-full flex justify-center items-start">
      <Card
        className="h-full w-full"
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <CardHeader
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          floated={false}
          shadow={false}
          className="rounded-none overflow-visible"
        >
          <div className="flex items-center justify-between gap-8">
            <div>
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="h5"
                color="blue-gray"
              >
                Attendance Logs
              </Typography>
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                color="gray"
                className="mt-1 font-normal"
              >
                See information about {unit?.firstName}'s attendance
              </Typography>
            </div>
          </div>
        </CardHeader>
        <CardBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="overflow-auto px-0"
        >
          <table className="mt-4 w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head, index) => (
                  <th
                    key={head}
                    className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                  >
                    <Typography
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      variant="small"
                      color="blue-gray"
                      className="flex items-center justify-between gap-2 leading-none opacity-70 !font-bold text-gray-900"
                    >
                      {head}
                      {index !== TABLE_HEAD.length - 1 && (
                        <ChevronUpDownIcon
                          strokeWidth={2}
                          className="h-4 w-4"
                        />
                      )}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {attendanceLogs.map((attendanceLog, index) => {
                const isLast = index === attendanceLogs.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr
                    key={attendanceLog._id}
                    className="hover:bg-gray-100 cursor-pointer hover:shadow-md"
                  >
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Chip
                            className="p-2 h-full text-center"
                            color={
                              !attendanceLog.type ||
                              attendanceLog.type === "checkin"
                                ? "green"
                                : "red"
                            }
                            value={
                              attendanceLog.type === "checkin"
                                ? "checked in"
                                : "checked out"
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        className="font-normal"
                      >
                        {attendanceLog.user
                          ? attendanceLog.user.firstName +
                            " " +
                            attendanceLog.user.lastName
                          : "N/A"}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        className="font-normal"
                      >
                        {format(
                          new Date(attendanceLog.timestamp),
                          "MM/dd/yyyy hh:mm a"
                        )}
                      </Typography>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
        <CardFooter
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="flex items-center justify-between border-t border-blue-gray-50 p-4"
        >
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            setRowsPerPage={setRowsPerPage}
            elementsPerPageOptions={elementsPerPageOptions}
          />
        </CardFooter>
      </Card>
    </div>
  );
};
