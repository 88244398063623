import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import {
  Typography,
  Card,
  CardHeader,
  CardBody,
  IconButton,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Chip,
  Avatar,
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";
import { FunctionComponent, useState } from "react";
import { MiniProfile } from "../../../Components/MiniProfile";
interface StaffMember {
  id: string;
  name: string;
  img: string;
}
interface ContentCardPropsType {
  id: string;
  img: string;
  staff: StaffMember[];
  students: string[];
  room: string;
  present: number;
  absent: number;
  numberOfStudents: number;
}
export const RoomsCard: FunctionComponent<ContentCardPropsType> = (props) => {
  const [openPopover, setOpenPopover] = useState<string | null>(null);

  const triggers = (id: string) => ({
    onClick: () => setOpenPopover(id),
    onMouseEnter: () => {
      if (openPopover !== null) {
        setOpenPopover(id);
      }
    },
    onMouseLeave: () => setOpenPopover(null),
  });

  return (
    <Card
      className="mb-8 rounded-lg border border-blue-gray-50"
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
    >
      <CardHeader
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        shadow={false}
        color="transparent"
        className="relative"
      >
        <div>
          <div className="pt-10 h-80">
            <img
              src={props.img}
              className="h-full w-full rounded rounded-t-lg"
              alt="avatar"
            />
          </div>
          <div className="flex items-end justify-between mt-4">
            <div className="flex items-end gap-3">
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                className="capitalize !text-gray-900 "
                variant="h6"
              >
                <a href={`/unit/${props.id}`} className="hover:underline">
                  {props.room}
                </a>
              </Typography>
            </div>
            <Menu placement="bottom-end">
              <MenuHandler>
                <IconButton
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  color="gray"
                  variant="text"
                  size="sm"
                >
                  <EllipsisVerticalIcon className="h-6 w-6" />
                </IconButton>
              </MenuHandler>
              <MenuList
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                <MenuItem
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  Menu Item 1
                </MenuItem>
                <MenuItem
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  Menu Item 2
                </MenuItem>
                <MenuItem
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  Menu Item 3
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        </div>
      </CardHeader>
      <CardBody
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <div className="flex w-full items-center justify-between">
          <div className=" flex items-center">
            <div className="flex items-center pr-4">
              {props.staff.map((staff, key) => (
                <Popover
                  key={staff.name + staff.id}
                  open={staff.id === openPopover}
                  handler={setOpenPopover}
                >
                  <PopoverHandler {...triggers(staff.id)}>
                    <div className="-mr-4 cursor-pointer">
                      <Avatar
                        src={staff.img}
                        size="sm"
                        className="rounded-full border-2 hover:z-10 focus:z-10 !border-white"
                        alt="avatar"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                      />
                    </div>
                  </PopoverHandler>
                  <PopoverContent
                    {...triggers(staff.id)}
                    className="z-50 max-w-[24rem]"
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  >
                    <MiniProfile img={staff.img} name={staff.name} />
                  </PopoverContent>
                </Popover>
              ))}
            </div>
          </div>
          <div className="w-max cursor-pointer">
            <Chip
              variant="ghost"
              size="sm"
              color={
                props.present / props.numberOfStudents > 0.8 ? "green" : "red"
              }
              value={props.numberOfStudents}
            />
          </div>
        </div>
        <div className="mt-3 flex w-full items-center justify-between">
          <Typography
            className="!text-sm  !font-normal !text-gray-700"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            Staff
          </Typography>
          <Typography
            className="!text-sm !font-normal !text-gray-700"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            Attendance
          </Typography>
        </div>
      </CardBody>
    </Card>
  );
};
