import { FunctionComponent, useEffect, useState } from "react";
import {
  Input,
  Typography,
  Textarea,
  Button,
  Card,
  CardBody,
} from "@material-tailwind/react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { urlServer } from "../../Configuration/heroku";

export const RoomOverview: FunctionComponent<{ room: any }> = ({ room }) => {
  let { id } = useParams();

  const [roomData, setRoomData] = useState({
    name: "",
    roomNumber: "",
    capacity: "",
    description: "",
  });

  useEffect(() => {
    if (room) {
      setRoomData({
        name: room.name || "",
        roomNumber: room.roomNumber || "",
        capacity: room.capacity || "",
        description: room.description || "",
      });
    }
  }, [room]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setRoomData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRoomUpdate = async () => {
    try {
      await axios.put(`${urlServer}/api/room/${id}`, {
        name: roomData.name,
        capacity: roomData.capacity,
        description: roomData.description,
        roomNumber: roomData.roomNumber,
      });
    } catch (error) {
      console.error("Error updating room:", error);
    }
  };
  return (
    <Card
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      className="w-full"
    >
      <CardBody
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="flex flex-col"
      >
        <Typography
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          variant="h5"
          color="blue-gray"
        >
          Room Information
        </Typography>
        <Typography
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          variant="small"
          className="text-gray-600 font-normal mt-1"
        >
          Update your room information below.
        </Typography>
        <div className="flex flex-col mt-8">
          <div className="mb-6 flex flex-col items-end gap-4 md:flex-row">
            <div className="w-full">
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="small"
                color="blue-gray"
                className="mb-2 font-medium"
              >
                Name
              </Typography>
              <Input
                crossOrigin={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                size="lg"
                placeholder="Room Name"
                labelProps={{
                  className: "hidden",
                }}
                className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                value={roomData.name}
                name="name"
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="small"
                color="blue-gray"
                className="mb-2 font-medium"
              >
                Rooom Number
              </Typography>
              <Input
                crossOrigin={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                size="lg"
                placeholder="Room Number"
                labelProps={{
                  className: "hidden",
                }}
                className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                value={roomData.roomNumber}
                name="roomNumber"
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="small"
                color="blue-gray"
                className="mb-2 font-medium"
              >
                Capacity
              </Typography>
              <Input
                crossOrigin={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                size="lg"
                placeholder="Capacity"
                labelProps={{
                  className: "hidden",
                }}
                className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                value={roomData.capacity}
                name="capacity"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mb-6 flex flex-col gap-4 md:flex-row">
            <div className="w-full">
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="small"
                color="blue-gray"
                className="mb-2 font-medium"
              >
                Notes
              </Typography>{" "}
              <Textarea
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                value={roomData.description}
                className="border p-1 w-full"
                name="description"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <Button
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="float-end"
          onClick={handleRoomUpdate}
        >
          Update
        </Button>
      </CardBody>
    </Card>
  );
};

export default RoomOverview;
