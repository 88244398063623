import React, { useState, FunctionComponent } from "react";
import {
  Input,
  Typography,
  Button,
  Card,
  CardBody,
  Textarea,
  Alert,
  Switch,
} from "@material-tailwind/react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { urlServer } from "../Configuration/heroku";
import { IUnit } from "../Data/unit";
import Allergies from "../Data/allergies.json";
import Medications from "../Data/medications.json";
import { MultiSelect } from "react-multi-select-component";

export const UnitProfile2: FunctionComponent<{
  initialUnit: IUnit;
}> = ({ initialUnit }) => {
  const { id } = useParams();

  const [unit, setUnit] = useState<IUnit | null>(initialUnit);
  const [open, setOpen] = useState<boolean>(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setUnit((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleUserUpdate = async () => {
    try {
      await axios.put(`${urlServer}/api/unit/${id}`, {
        firstName: unit?.firstName,
        lastName: unit?.lastName,
        dateOfBirth: unit?.dateOfBirth,
        address: unit?.address,
        city: unit?.city,
        state: unit?.state,
        zipCode: unit?.zipCode,
        notes: unit?.notes,
        medications: unit?.medications,
        allergies: unit?.allergies,
        resuscitate: unit?.resuscitate,
      });

      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    } catch (error) {
      console.error("Error updating unit:", error);
    }
  };
  const handleMultiSelectChange = (
    selectedOptions: { label: string; value: string }[],
    field: "medications" | "allergies"
  ) => {
    setUnit((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        [field]: selectedOptions.map((option) => option.value),
      };
    });
  };

  return (
    <>
      {open && (
        <Alert className="rounded-none border-l-4 border-[#2ec946] bg-[#2ec946]/10 font-medium text-[#2ec946]">
          Success
        </Alert>
      )}
      <Card
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="w-full"
      >
        <CardBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="flex flex-col"
        >
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="h5"
            color="blue-gray"
          >
            Client Information
          </Typography>
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="small"
            className="text-gray-600 font-normal mt-1"
          >
            Update your client information below.
          </Typography>
          <div className="flex flex-col mt-8">
            <div className="mb-6 flex flex-col items-end gap-4 md:flex-row">
              <div className="w-full">
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="small"
                  color="blue-gray"
                  className="mb-2 font-medium"
                >
                  First Name
                </Typography>
                <Input
                  crossOrigin={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  size="lg"
                  placeholder="First Name"
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                  value={unit?.firstName}
                  name="firstName"
                  onChange={handleChange}
                />
              </div>
              <div className="w-full">
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="small"
                  color="blue-gray"
                  className="mb-2 font-medium"
                >
                  Last Name
                </Typography>
                <Input
                  crossOrigin={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  size="lg"
                  placeholder="Last Name"
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                  value={unit?.lastName}
                  name="lastName"
                  onChange={handleChange}
                />
              </div>
              <div className="w-full">
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="small"
                  color="blue-gray"
                  className="mb-2 font-medium"
                >
                  Medications
                </Typography>
                <div className="w-96">
                  <MultiSelect
                    options={Medications}
                    value={
                      unit?.medications?.map((medication) => ({
                        label:
                          medication.charAt(0).toUpperCase() +
                          medication.slice(1),
                        value: medication,
                      })) || []
                    }
                    onChange={(
                      selectedOptions: { label: string; value: string }[]
                    ) =>
                      handleMultiSelectChange(selectedOptions, "medications")
                    }
                    labelledBy="Select Medications"
                    isCreatable
                    hasSelectAll={false}
                  />
                </div>
              </div>
              <div className="w-full">
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="small"
                  color="blue-gray"
                  className="mb-2 font-medium"
                >
                  Allergies
                </Typography>
                <div className="w-96">
                  {" "}
                  <MultiSelect
                    options={Allergies}
                    value={
                      unit?.allergies?.map((allergy) => ({
                        label:
                          allergy.charAt(0).toUpperCase() + allergy.slice(1),
                        value: allergy,
                      })) || []
                    }
                    onChange={(
                      selectedOptions: { label: string; value: string }[]
                    ) => handleMultiSelectChange(selectedOptions, "allergies")}
                    labelledBy="Select Allergies"
                    isCreatable
                    hasSelectAll={false}
                  />
                </div>
              </div>
              <div className="w-full">
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="small"
                  color="blue-gray"
                  className="mb-2 font-medium"
                >
                  Do Not Resuscitate
                </Typography>
                <div className="w-96">
                  <Switch
                    crossOrigin={undefined}
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    defaultChecked
                    checked={unit?.resuscitate || false}
                    onChange={(e) =>
                      setUnit((prev) => {
                        if (!prev) return null;
                        return {
                          ...prev,
                          resuscitate: e.target.checked,
                        };
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="mb-6 flex flex-col items-end gap-4 md:flex-row">
              <div className="w-full">
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="small"
                  color="blue-gray"
                  className="mb-2 font-medium"
                >
                  Date Of Birth
                </Typography>
                <Input
                  type="date"
                  value={moment(unit?.dateOfBirth).format("YYYY-MM-DD")}
                  className="border p-1 w-full"
                  crossOrigin={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  onChange={handleChange}
                  name="dateOfBirth"
                />
              </div>
              <div className="w-full">
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="small"
                  color="blue-gray"
                  className="mb-2 font-medium"
                >
                  Address
                </Typography>
                <Input
                  crossOrigin={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  size="lg"
                  placeholder="Address"
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                  value={unit?.address}
                  name="address"
                  onChange={handleChange}
                />
              </div>
              <div className="w-full">
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="small"
                  color="blue-gray"
                  className="mb-2 font-medium"
                >
                  City
                </Typography>
                <Input
                  crossOrigin={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  size="md"
                  placeholder="city"
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                  value={unit?.city}
                  name="city"
                  onChange={handleChange}
                />
              </div>
              <div className="w-full">
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="small"
                  color="blue-gray"
                  className="mb-2 font-medium"
                >
                  State
                </Typography>
                <Input
                  crossOrigin={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  size="md"
                  placeholder="state"
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                  value={unit?.state}
                  name="state"
                  onChange={handleChange}
                />
              </div>
              <div className="w-full">
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="small"
                  color="blue-gray"
                  className="mb-2 font-medium"
                >
                  Zip Code
                </Typography>
                <Input
                  crossOrigin={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  size="md"
                  placeholder="Zip Code"
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                  value={unit?.zipCode}
                  name="zipCode"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-6 flex flex-col items-end gap-4 md:flex-row">
              <div className="w-full">
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="small"
                  color="blue-gray"
                  className="mb-2 font-medium"
                >
                  Notes
                </Typography>
                <Textarea
                  name="notes"
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  rows={6}
                  placeholder="notes"
                  labelProps={{
                    className: "hidden",
                  }}
                  className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                  value={unit?.notes}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div>
              {" "}
              <Button
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                className="w-32 float-right"
                onClick={handleUserUpdate}
              >
                Update
              </Button>{" "}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default UnitProfile2;
