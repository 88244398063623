import {
  ChatBubbleLeftRightIcon,
  EllipsisVerticalIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import {
  Typography,
  Card,
  CardHeader,
  CardBody,
  IconButton,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Chip,
  Avatar,
  Popover,
  PopoverHandler,
  PopoverContent,
  List,
  ListItem,
  ListItemPrefix,
} from "@material-tailwind/react";
import { FunctionComponent, useState } from "react";

interface ContentCardPropsType {
  id: string;
  firstName: string;
  lastName: string;
  img: string;
  room?: string;
  date?: string;
  icon?: React.ReactNode;
  attendanceStatus?: string;
  onChangeAttendanceStatus?: () => {};
}
export const ContentCard: FunctionComponent<ContentCardPropsType> = (props) => {
  const [openPopover, setOpenPopover] = useState<string | null>(null);

  const triggers = (id: string) => ({
    onClick: () => setOpenPopover(id),
    onMouseEnter: () => {
      if (openPopover !== null) {
        setOpenPopover(id);
      }
    },
    onMouseLeave: () => setOpenPopover(null),
  });

  return (
    <Card
      className="mb-8 rounded-lg border border-blue-gray-50"
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
    >
      <CardHeader
        shadow={false}
        color="transparent"
        className="relative"
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <div>
          <div className="pt-10 h-80">
            <img
              src={props.img}
              className="h-full w-full rounded rounded-t-lg"
              alt="avatar"
            />
          </div>
          <div className="flex items-end justify-between mt-4">
            <div className="flex items-end gap-3">
              <Typography
                className="capitalize !text-gray-900"
                variant="h6"
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                <a href={`/unit/${props.id}`} className="hover:underline">
                  {props.firstName} {props.lastName}
                </a>
              </Typography>
            </div>
          </div>
        </div>
      </CardHeader>
    </Card>
  );
};
