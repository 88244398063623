import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  IconButton,
  Typography,
  DialogFooter,
  Card,
  CardBody,
  CardFooter,
  Checkbox,
} from "@material-tailwind/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { urlServer } from "../Configuration/heroku";

interface Room {
  _id: string;
  name: string;
  roomNumber: string;
  description: string;
}

interface SelectDialogProps {
  open: boolean;
  onClose: () => void;
  rooms: Room[];
  user: any;
}

export const UserToRoom: FunctionComponent<SelectDialogProps> = (props) => {
  const { id } = useParams();
  let { facilityId } = useParams();
  const [rooms, setRooms] = useState<Room[]>([]);
  const [selectedRooms, setSelectedRooms] = useState<Set<string>>(new Set());
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const fetchRooms = async () => {
    try {
      const response = await axios.get(`${urlServer}/api/rooms/${facilityId}`);
      let data = response.data;

      const userAssignedRooms = props.user.rooms || [];

      data = data.room.filter(
        (room: Room) => !userAssignedRooms.includes(room._id)
      );

      setRooms(data);
    } catch (error) {
      console.error("Error fetching room data:", error);
    }
  };

  useEffect(() => {
    fetchRooms();
  }, [props.rooms]);

  const handleCheckboxChange = (roomId: string) => {
    setSelectedRooms((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(roomId)) {
        newSelected.delete(roomId);
      } else {
        newSelected.add(roomId);
      }
      return newSelected;
    });
  };

  const handleSubmit = async () => {
    try {
      await axios.put(`${urlServer}/api/user/${id}/rooms`, {
        roomIds: Array.from(selectedRooms),
      });
      setSelectedRooms(new Set());
      setRooms(rooms);
      fetchRooms();
      props.onClose();
    } catch (error) {
      console.error("Error assigning rooms:", error);
    }
  };

  return (
    <>
      {rooms.length === 0 ? (
        <>
          {" "}
          <Dialog
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            className="p-4"
            open={props.open}
            handler={props.onClose}
          >
            <DialogHeader
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              className="justify-between pb-0"
            >
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                color="blue-gray"
                className="mb-1 font-bold"
              >
                No Rooms Available
              </Typography>
              <IconButton
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                color="gray"
                size="sm"
                variant="text"
                onClick={props.onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                  className="h-4 w-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </IconButton>
            </DialogHeader>
          </Dialog>
        </>
      ) : (
        <>
          <Dialog
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            className="p-4"
            open={props.open}
            handler={props.onClose}
          >
            <DialogHeader
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              className="justify-between pb-0"
            >
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                color="blue-gray"
                className="mb-1 font-bold"
              >
                Assign Room
              </Typography>
              <IconButton
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                color="gray"
                size="sm"
                variant="text"
                onClick={props.onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                  className="h-4 w-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </IconButton>
            </DialogHeader>
            <DialogBody
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              className="overflow-y-scroll pt-0"
            >
              {rooms.map((room: Room, index: number) => (
                <div className="flex flex-col gap-4 items-center" key={index}>
                  <Card
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    className="mt-6 w-full h-40"
                  >
                    <CardBody
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                    >
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        variant="h6"
                        color="blue-gray"
                        className="mb-2"
                      >
                        {room.name} ({room.roomNumber})
                      </Typography>
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                      >
                        {room.description}
                      </Typography>
                    </CardBody>
                    <CardFooter
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      className="pt-0"
                    >
                      <Checkbox
                        crossOrigin={undefined}
                        id={room._id}
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        label="Select Room"
                        checked={selectedRooms.has(room._id)}
                        onChange={() => handleCheckboxChange(room._id)}
                      />
                    </CardFooter>
                  </Card>
                </div>
              ))}
            </DialogBody>
            <DialogFooter
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              className="gap-2"
            >
              <Button
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </DialogFooter>
          </Dialog>
        </>
      )}
    </>
  );
};

export default UserToRoom;
