import { Typography, Input, Button, Alert } from "@material-tailwind/react";
import { auth } from "../../../Configuration/firebase";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { AlertState, AlertType } from "../../../Data/alert";
import { validateEmail } from "../../../Helpers/common";
export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [alert, setAlert] = useState<AlertState>({
    show: false,
    message: "",
    type: "green",
  });

  const sendResetEmail = async () => {
    const isEmailValid = validateEmail(email);
    setError(isEmailValid ? "" : "Please enter a valid email address");
    if (!error && isEmailValid) {
      try {
        await sendPasswordResetEmail(auth, email);
        showAlert("Password reset email sent. Check your inbox.", "green");
      } catch (error) {
        console.error("Error sending password reset email:", error);
        showAlert("There was an error sending the email", "red");
      }
    }
  };

  const showAlert = (message: string, type: AlertType) => {
    setAlert({ show: true, message, type });

    // Hide the alert after 3 seconds
    setTimeout(() => {
      setAlert({ show: false, message: "", type: "green" });
    }, 3000);
  };

  return (
    <section className="grid h-screen items-center lg:grid-cols-2">
      <div className="my-auto p-8 text-center sm:p-10 md:p-20 xl:px-32 xl:py-24">
        <Typography
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          variant="h3"
          color="blue-gray"
          className="mb-2"
        >
          Forgot Password
        </Typography>
        <Typography
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          color="gray"
          className="mb-16 font-normal"
        >
          Enter your email to reset your password
        </Typography>
        <form action="#" className="mx-auto max-w-[24rem] text-left">
          <div className="mb-6">
            <label htmlFor="email">
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="small"
                className="mb-2 block font-medium text-gray-900"
              >
                Your Email
              </Typography>
            </label>
            <Input
              crossOrigin={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              id="email"
              color="gray"
              size="lg"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="name@mail.com"
              className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
              labelProps={{ className: "hidden" }}
            />
            {error && <span style={{ color: "red" }}>{error}</span>}
          </div>
          <div className="flex flex-wrap items-center justify-between gap-2">
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              as="a"
              href="#"
              color="gray"
              className="font-medium"
              onClick={(e) => {
                e.preventDefault();
                navigate("/login");
              }}
            >
              Go back to Login
            </Typography>
          </div>
          <Button
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            color="gray"
            size="lg"
            className="mt-6"
            fullWidth
            onClick={sendResetEmail}
          >
            send
          </Button>
        </form>
      </div>
      <img
        src={`https://www.material-tailwind.com/img/bg-sign-in.avif`}
        alt="background image"
        className="hidden h-screen w-full object-cover lg:block"
      />
      {alert.show && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50">
          <Alert
            color={alert.type}
            className="inline-block max-w-xl text-center"
          >
            {alert.message}
          </Alert>
        </div>
      )}
    </section>
  );
};
