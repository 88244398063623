import React, { useEffect, useState } from "react";
import { Card, CardBody, Typography } from "@material-tailwind/react";
import {
  UserIcon,
  UserGroupIcon,
  HomeIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/solid";
import axios from "axios";
import { urlServer } from "../../../Configuration/heroku";
import { useParams } from "react-router-dom";

interface DataPoint {
  icon: React.ReactNode;
  title: string;
  value: string;
  description: string;
  color: string;
}

const OverviewCard: React.FC = () => {
  let { facilityId } = useParams();
  const [users, setUsers] = useState<any[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [events, setEvents] = useState<any[]>([]);

  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const getUsers = async () => {
    try {
      const response = await axios.get(`${urlServer}/api/${facilityId}/users`);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching units:", error);
    }
  };
  const getClients = async () => {
    try {
      const response = await axios.get(`${urlServer}/api/units/${facilityId}`);
      setClients(response.data.units);
    } catch (error) {
      console.error("Error fetching units:", error);
    }
  };
  const getEvents = async () => {
    try {
      const response = await axios.get(`${urlServer}/api/events/${facilityId}`);
      setEvents(response.data);
    } catch (error) {
      console.error("Error fetching units:", error);
    }
  };
  useEffect(() => {
    getUsers();
    getClients();
    getEvents();
  }, []);
  const dataPoints: DataPoint[] = [
    {
      icon: <UserIcon className="!w-5 !h-5 " />,
      title: "Clients",
      value: clients.length.toString(),
      color:
        Number(clients.length.toString()) > 0
          ? "!text-green-500"
          : "!text-red-500",
      description: "",
    },
    {
      icon: <UserGroupIcon className="!w-5 !h-5 " />,
      title: "Staff",
      value: users.length.toString(),
      color:
        Number(users.length.toString()) > 0
          ? "!text-green-500"
          : "!text-red-500",
      description: "",
    },
    {
      icon: <CalendarDaysIcon className="!w-5 !h-5 " />,
      title: "Events",
      value: events.length.toString(),
      color:
        Number(events.length.toString()) > 0
          ? "!text-green-500"
          : "!text-red-500",
      description: "",
    },
  ];

  return (
    <Card
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      className="w-full border !border-blue-gray-100 p-2"
    >
      <Typography
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        variant="h6"
        className="m-3 flex gap-1"
      >
        <HomeIcon className="!w-5 !h-5 " /> Site Overview
      </Typography>
      <CardBody
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="py-2 grid grid-cols-1 md:grid-cols-3 gap-1"
      >
        {dataPoints.map((data, index) => (
          <div
            key={index}
            className="flex flex-col items-center space-y-1 text-center p-2 "
          >
            {data.icon}

            <Typography
              variant="small"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              className="text-sm font-semibold"
            >
              {data.title}
            </Typography>
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="h3"
              className={`${data.color}`}
            >
              {data.value}
            </Typography>
            <Typography
              variant="small"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              className="text-sm font-semibold"
            >
              {data.description}
            </Typography>
          </div>
        ))}
      </CardBody>
    </Card>
  );
};

export default OverviewCard;
