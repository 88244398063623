import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../Configuration/firebase";
import { IUser } from "../Data/user";
import { useEffect, useState } from "react";
import { MessageProps } from "../Components/Message";
import { format, isToday, isYesterday, differenceInDays } from "date-fns";
import axios from "axios";
import { urlServer } from "../Configuration/heroku";

export const addRoom = async (
  roomName: string,
  participants: string[],
  userId: string,
  facilityId: string
) => {
  try {
    const roomRef = await addDoc(collection(db, "chatRooms"), {
      name: roomName,
      createdAt: serverTimestamp(),
      createdBy: userId,
      participants: participants,
    });

    if (roomRef) {
      await Promise.all(
        participants.map(async (participant) => {
          await axios.put(
            `${urlServer}/api/user/${participant}/add-chat-room/`,
            {
              chatRoom: {
                name: roomName,
                firebaseId: roomRef.id,
                facilityId: facilityId,
              },
            }
          );
        })
      );
    }
  } catch (error) {
    // Handle errors here
    console.error("Error adding room:", error);
    throw new Error("Failed to add room");
  }
};

export const sendMessage = async (
  roomId: string,
  user: IUser,
  text: string
) => {
  const roomRef = doc(db, "chatRooms", roomId);
  const roomDoc = await getDoc(roomRef);

  if (!roomDoc.exists) {
    throw new Error("Room does not exist.");
  }

  const roomData = roomDoc.data();

  if (!roomData || !roomData.participants.includes(user._id)) {
    throw new Error("User is not a participant of this room.");
  }

  await addDoc(collection(db, "chatRooms", roomId, "messages"), {
    id: user._id,
    name: user.firstName + " " + user.lastName,
    avatar: user.avatar,
    text: text,
    createdAt: serverTimestamp(),
  });
};

export const GetMessages = (roomId: string) => {
  const [messages, setMessages] = useState<MessageProps[]>([]);

  useEffect(() => {
    const messagesRef = collection(db, "chatRooms", roomId, "messages");
    const q = query(messagesRef, orderBy("createdAt"), limit(50));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messagesData = snapshot.docs.map((doc: any) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(messagesData);
    });

    return () => unsubscribe();
  }, [roomId]);

  return messages;
};

export const formatTimestampForRoomElement = (timestamp: Timestamp) => {
  const date = timestamp.toDate();

  if (isToday(date)) {
    return format(date, "p"); // e.g., 12:04 PM
  } else if (isYesterday(date)) {
    return "Yesterday";
  } else if (differenceInDays(new Date(), date) < 7) {
    return format(date, "EEEE"); // e.g., Monday
  } else {
    return format(date, "P"); // e.g., 06/12/24
  }
};
