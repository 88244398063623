import { Card, List, ListItem, ListItemPrefix } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { IUserFacility } from "../Data/user";
import { IRoute, Role, RolePermissions, Routes } from "../Data/route";

export const SidebarWithLogo = () => {
  const navigate = useNavigate();
  const [role, setRole] = useState<Role | null>(null);
  const [facilityId, setFacilityId] = useState<string | null>(null);

  useEffect(() => {
    const userData = localStorage.getItem("user");
    const facilityData = localStorage.getItem("facility");
    if (userData && facilityData) {
      const user = JSON.parse(userData);
      const facilityId = JSON.parse(facilityData)._id;
      setFacilityId(facilityId);

      if (user && user.facilities && user.facilities.length > 0) {
        const foundFacility: IUserFacility = user.facilities.find(
          (facility: IUserFacility) => facility.facilityId === facilityId
        );
        if (foundFacility) {
          setRole(foundFacility.role);
        }
      }
    }
  }, []);

  return (
    <Card
      className="h-[calc(100vh)] w-full max-w-[5rem] p-4 shadow-xl shadow-blue-gray-900/5 items-center"
      style={{ backgroundColor: "#d4e8ff" }}
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
    >
      <div
        className="my-4 flex items-center gap-4 cursor-pointer"
        onClick={() => navigate(`/facility/${facilityId}/dashboard`)}
      >
        <img
          src="https://eduguardianicons.s3.amazonaws.com/android-chrome-384x384.png"
          alt="brand"
          className="h-12 w-16"
        />
      </div>
      {role && (
        <List
          className="min-w-0"
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          {Routes.map((route: IRoute) => {
            const actualPath = route.path.includes(":facilityId")
              ? route.path.replace(":facilityId", facilityId || "")
              : route.path;

            return (
              RolePermissions[role].includes(route.name) && (
                <ListItem
                  key={actualPath}
                  onClick={() => navigate(actualPath)}
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <ListItemPrefix
                    className="mr-0"
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  >
                    <route.icon className="h-8 w-8" />
                  </ListItemPrefix>
                </ListItem>
              )
            );
          })}
        </List>
      )}
    </Card>
  );
};
