import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  IconButton,
  Typography,
  DialogFooter,
  Input,
} from "@material-tailwind/react";
import { FunctionComponent, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { urlServer } from "../Configuration/heroku";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { validateEmail } from "../Helpers/common";
import { urlClient } from "../Configuration/client";

interface SelectDialogProps {
  open: boolean;
  onClose: () => void;
}

const Caregivers: FunctionComponent<SelectDialogProps> = (props) => {
  const { id } = useParams();
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("caregiver");
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(true);

  const handleClear = () => {
    setFirstName("");
    setLastName("");
    setRole("caregiver");
    setEmail("");
    setIsEmailValid(true);
  };

  const facilityData = localStorage.getItem("facility");
  const facilityId = facilityData ? JSON.parse(facilityData)._id : "";

  const actionCodeSettings = {
    url: `${urlClient}/sign-up?email=${email}&facilityId=${facilityId}&unitId=${id}`,
    handleCodeInApp: true,
  };

  const sendInvitation = async () => {
    if (!validateEmail(email)) {
      setIsEmailValid(false);
      return;
    }

    setIsEmailValid(true);

    const auth = getAuth();
    const facilityData = localStorage.getItem("facility");

    if (!facilityData) {
      console.error("Facility data not found in local storage.");
      return;
    }

    const facilityId = JSON.parse(facilityData)._id;

    if (firstName && lastName && email && role && auth && auth.currentUser) {
      try {
        // Step 1: Create Invitation
        await axios.post(`${urlServer}/api/${facilityId}/create-invitation`, {
          firstName,
          lastName,
          email,
          role,
          createdBy: auth.currentUser?.email,
          unitId: id,
        });

        // Step 2: Send Sign-In Link to Email
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);

        handleClear();
        props.onClose();
      } catch (error) {
        console.error("Error sending invitation:", error);
      }
    } else {
      console.error("Invalid input data.");
    }
  };

  return (
    <Dialog
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      className="p-4"
      open={props.open}
      handler={props.onClose}
    >
      <DialogHeader
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="justify-between pb-0"
      >
        <Typography
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          color="blue-gray"
          className="mb-1 font-bold"
        >
          Assign Caregiver
        </Typography>
        <IconButton
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          color="gray"
          size="sm"
          variant="text"
          onClick={props.onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            className="h-4 w-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </DialogHeader>
      <DialogBody
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="overflow-y-scroll pt-0"
      >
        <div className="flex items-center flex-col md:flex-row gap-4 mt-6">
          <div className="w-full">
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="small"
              color="blue-gray"
              className="mb-1 font-medium"
            >
              First Name
            </Typography>
            <Input
              crossOrigin={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              color="gray"
              placeholder="First Name"
              labelProps={{
                className: "hidden",
              }}
              className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
              name="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="w-full">
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="small"
              color="blue-gray"
              className="mb-1 font-medium"
            >
              Last Name
            </Typography>
            <Input
              crossOrigin={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              color="gray"
              placeholder="Last Name"
              labelProps={{
                className: "hidden",
              }}
              className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
              name="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className="w-full">
          {!isEmailValid && (
            <p className="mb-4 mt-1 text-red-600">Email is not valid!</p>
          )}
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="small"
            color="blue-gray"
            className="mb-1 font-medium"
          >
            Email
          </Typography>
          <Input
            crossOrigin={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            color="gray"
            placeholder="Email"
            labelProps={{
              className: "hidden",
            }}
            className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
            name="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value.trim());
              setIsEmailValid(true);
            }}
          />
        </div>
      </DialogBody>
      <DialogFooter
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="gap-2"
      >
        <Button
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          onClick={sendInvitation}
        >
          Submit
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default Caregivers;
