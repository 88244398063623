import { initializeApp } from "firebase/app";
import {
  getAuth,
  initializeAuth,
  indexedDBLocalPersistence,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { Capacitor } from "@capacitor/core";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD2HjqJYkQ4mBbtbS88DBjByMTfl8XV238",
  authDomain: "myvitalpath-c4f97.firebaseapp.com",
  projectId: "myvitalpath-c4f97",
  storageBucket: "myvitalpath-c4f97.firebasestorage.app",
  messagingSenderId: "931482923520",
  appId: "1:931482923520:web:3e2630a0ba189eaba45ee5",
  measurementId: "G-XGZBXCHSLF",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Auth with platform-specific persistence for native platforms
export const auth = Capacitor.isNativePlatform()
  ? initializeAuth(app, {
      persistence: indexedDBLocalPersistence,
    })
  : getAuth(app);

export const db = getFirestore(app);
