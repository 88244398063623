import { Typography, Card } from "@material-tailwind/react";
import { IMessage } from "../../Data/chat";
import { FunctionComponent, useEffect } from "react";
import { IUser } from "../../Data/user";

interface BubbleProps {
  message: IMessage;
  user: IUser;
}

const TextMsg: FunctionComponent<BubbleProps> = (props) => {
  const outgoing = props.user._id === props.message.senderId;

  return (
    <div className={`flex ${outgoing ? "justify-end" : "justify-start"}`}>
      <Card
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className={`p-4 ${
          outgoing ? "bg-blue-500" : "bg-gray-100"
        } rounded-lg max-w-max`}
        style={{ maxWidth: "48%" }}
      >
        <Typography
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className={`${outgoing ? "text-white" : "text-gray-800"} break-words`}
        >
          {props.message.text}
        </Typography>
      </Card>
    </div>
  );
};

export default TextMsg;
