import { Typography, Input, Button, Alert } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { validatePassword } from "../../../Helpers/password";
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
  updatePassword,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../Configuration/firebase";
import axios from "axios";
import { AlertState, AlertType } from "../../../Data/alert";
import { urlServer } from "../../../Configuration/heroku";

export const SignUp = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [errors, setErrors] = useState<string[]>([]);
  const [passwordsMatch, setPasswordsMatch] = useState<boolean | null>(null);
  const [alert, setAlert] = useState<AlertState>({
    show: false,
    message: "",
    type: "green",
  });

  const showAlert = (message: string, type: AlertType) => {
    setAlert({ show: true, message, type });

    // Hide the alert after 3 seconds
    setTimeout(() => {
      setAlert({ show: false, message: "", type: "green" });
    }, 3000);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
    setErrors(validatePassword(value));
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setConfirmPassword(value);
    setPasswordsMatch(value === password);
  };

  const handleSignUp = async () => {
    const auth = getAuth();
    const urlParams = new URLSearchParams(window.location.search);
    const facilityIdFromURL = urlParams.get("facilityId");
    const unitIdFromURL = urlParams.get("unitId");
    let invitation = null;
    try {
      invitation = await axios.get(
        `${urlServer}/api/${facilityIdFromURL}/invitation`,
        {
          params: { email: email },
        }
      );
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 404) {
          showAlert("Invitation not found", "red");
        }
      }
      console.error(error);
    }

    if (
      invitation &&
      email &&
      password &&
      confirmPassword &&
      facilityIdFromURL &&
      isSignInWithEmailLink(auth, window.location.href)
    ) {
      try {
        const result = await signInWithEmailLink(
          auth,
          email,
          window.location.href
        );
        if (result) {
          const idToken = await result.user?.getIdToken();
          // Send token to backend
          await axios.post(`${urlServer}/auth/verify`, {
            idToken,
          });
          const user = result.user;

          // Create user document in Firestore
          const userRef = doc(db, "users", user.uid);
          await setDoc(userRef, {
            name: invitation.data.firstName + " " + invitation.data.lastName,
            email: user.email,
            avatar: user.photoURL,
          });
          await updatePassword(user, password);

          // Create user in Stripe
          const customer = await axios.post(
            `${urlServer}/api/payment/create-customer`,
            {
              name: `${invitation.data.firstName} ${invitation.data.lastName}`,
              email: email,
            }
          );

          // Create user in MongoDB
          const userResult = await axios.post(
            `${urlServer}/api/${facilityIdFromURL}/create-user`,
            {
              firstName: invitation.data.firstName,
              lastName: invitation.data.lastName,
              email: email,
              firebaseUid: user.uid,
              customerId: customer.data.customer.id,
              role: invitation.data.role,
              units: unitIdFromURL ? [unitIdFromURL] : [], // Ensure this is passed correctly
            },
            {
              headers: { "Content-Type": "application/json" }, // Explicitly set JSON headers
            }
          );

          const userMongoDB = userResult.data;
          if (
            userMongoDB &&
            userMongoDB.facilities &&
            userMongoDB.facilities.length > 0
          ) {
            // Complete invitation
            await axios.get(
              `${urlServer}/api/${facilityIdFromURL}/complete-invitation`,
              {
                params: { email: email },
              }
            );

            // Save in Cache
            localStorage.setItem("user", JSON.stringify(userMongoDB));
            // Get facility in MongoDB (FOR NOW ONLY FIRST ONE)
            const facilityResult = await axios.get(
              `${urlServer}/api/facility/${userMongoDB.facilities[0].facilityId}`
            );
            const facility = facilityResult.data;
            if (facility) {
              // Save in Cache
              localStorage.setItem("facility", JSON.stringify(facility));
              navigate(`/facility/${facility._id}/dashboard`);
            }
          }
        }
      } catch (error) {
        console.error("Error signing in with email link:", error);
      }
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailFromURL = urlParams.get("email");
    setEmail(emailFromURL || "");
  }, []);

  return (
    <section className="grid h-screen items-center lg:grid-cols-2">
      <div className="my-auto p-8 text-center sm:p-10 md:p-20 xl:px-32 xl:py-24">
        <Typography
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          variant="h3"
          color="blue-gray"
          className="mb-2"
        >
          Sign Up
        </Typography>
        <Typography
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          color="gray"
          className="mb-16 font-normal"
        >
          Enter your email and password to sign up
        </Typography>
        <form action="#" className="mx-auto max-w-[24rem] text-left">
          <div className="mb-6">
            <label htmlFor="email">
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="small"
                className="mb-2 block font-medium text-gray-900"
              >
                Your Email
              </Typography>
            </label>
            <Input
              crossOrigin={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              id="email"
              color="gray"
              size="lg"
              type="email"
              name="email"
              placeholder="name@mail.com"
              value={email}
              disabled
              className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
              labelProps={{ className: "hidden" }}
            />
          </div>
          <div className="mb-6">
            <label htmlFor="password">
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="small"
                className="mb-2 block font-medium text-gray-900"
              >
                Password
              </Typography>
            </label>
            <Input
              crossOrigin={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              id="password"
              color="gray"
              size="lg"
              type="password"
              name="password"
              placeholder="********"
              value={password}
              onChange={handlePasswordChange}
              className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
              labelProps={{ className: "hidden" }}
            />
            {errors.length > 0 && (
              <ul>
                {errors.map((error, index) => (
                  <li key={index} style={{ color: "red" }}>
                    {error}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="mb-6">
            <label htmlFor="password">
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="small"
                className="mb-2 block font-medium text-gray-900"
              >
                Confirm Password
              </Typography>
            </label>
            <Input
              crossOrigin={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              id="password"
              color="gray"
              size="lg"
              type="password"
              name="password"
              placeholder="********"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              disabled={password.length === 0 || errors.length > 1}
              className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
              labelProps={{ className: "hidden" }}
            />
            {passwordsMatch === false && (
              <p style={{ color: "red" }}>Passwords do not match!</p>
            )}
          </div>
          <Button
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            color="gray"
            size="lg"
            className="mt-6"
            fullWidth
            disabled={
              password.length === 0 ||
              confirmPassword.length === 0 ||
              errors.length > 1 ||
              passwordsMatch === false
            }
            onClick={handleSignUp}
          >
            sign up
          </Button>
        </form>
      </div>
      <img
        src={`https://www.material-tailwind.com/img/bg-sign-in.avif`}
        alt="background image"
        className="hidden h-screen w-full object-cover lg:block"
      />
      {alert.show && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50">
          <Alert
            color={alert.type}
            className="inline-block max-w-xl text-center"
          >
            {alert.message}
          </Alert>
        </div>
      )}
    </section>
  );
};
