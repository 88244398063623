import React, { useState, FC } from "react";

interface FaqItemProps {
  id: number;
  title: string;
  content: JSX.Element;
}
export const FaqItem: FC<FaqItemProps> = ({ id, title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-4">
      <h2 id={`accordion-open-heading-${id}`}>
        <button
          type="button"
          className="rounded-lg flex items-center justify-between w-full p-5 font-medium text-black border border-gray-200 dark:border-gray-700 dark:text-gray-400 bg-zinc-500 hover:bg-zinc-400 gap-3 transition duration-300 ease-in-out"
          aria-expanded={isOpen}
          aria-controls={`accordion-open-body-${id}`}
          onClick={toggleAccordion}
        >
          <span>{title}</span>
          <svg
            data-accordion-icon
            className={`w-3 h-3 transition-transform duration-300 ${
              isOpen ? "rotate-180" : "rotate-0"
            }`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id={`accordion-open-body-${id}`}
        className={`rounded-lg overflow-hidden transition-all duration-300 ease-in-out ${
          isOpen ? "max-h-96" : "max-h-0"
        }`}
        aria-labelledby={`accordion-open-heading-${id}`}
      >
        <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
          {content}
        </div>
      </div>
    </div>
  );
};
