import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  IconButton,
  Typography,
  DialogFooter,
  Input,
  Select,
  Option,
  Textarea,
} from "@material-tailwind/react";
import { ArrowUpTrayIcon, TrashIcon } from "@heroicons/react/24/solid";
import { FunctionComponent, useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { urlServer } from "../Configuration/heroku";

interface SelectDialogProps {
  open: boolean;
  onClose: () => void;
  announcements?: any;
}

export const Announcement: FunctionComponent<SelectDialogProps> = ({
  open,
  onClose,
  announcements,
}) => {
  const { id } = useParams();
  let { facilityId } = useParams();
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const [announcement, setAnnouncement] = useState({
    title: "",
    description: "",
    type: "",
    rooms: [],
    createdBy: user,
  });
  const [files, setFiles] = useState<File[]>([]);
  const [existingFiles, setExistingFiles] = useState<any[]>([]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
      e.target.value = "";
    }
  };

  const handleRemoveExistingFile = async (index: number) => {
    const updatedFiles = [...existingFiles];
    updatedFiles.splice(index, 1);
    setExistingFiles(updatedFiles);
  };

  const handleRemoveNewFile = (index: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
      | string
  ) => {
    if (typeof e === "string") {
      setAnnouncement((prev) => ({
        ...prev,
        type: e,
      }));
    } else {
      const { name, value } = e.target;
      setAnnouncement((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    formData.append("title", announcement.title);
    formData.append("description", announcement.description);
    formData.append("type", announcement.type);
    formData.append("createdBy", user._id);

    files.forEach((file) => {
      formData.append("attachments", file);
    });

    if (existingFiles.length > 0) {
      formData.append("existingFiles", JSON.stringify(existingFiles));
    }

    try {
      if (announcements) {
        await axios.put(
          `${urlServer}/api/announcement/${announcements.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        await axios.post(
          `${urlServer}/api/${facilityId}/announcement/${id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }
      onClose();
    } catch (error) {
      console.error("Error submitting announcement:", error);
    }
  };

  useEffect(() => {
    if (announcements) {
      setAnnouncement(announcements);
      setExistingFiles(announcements.attachments || []);
    }
  }, [announcements]);
  return (
    <>
      <Dialog
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="overflow-y-auto"
        open={open}
        handler={onClose}
      >
        <DialogHeader
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="justify-between pb-0"
        >
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            color="blue-gray"
            className="mb-1 font-bold"
          >
            {announcements ? "Edit Announcement" : "Create An Announcement"}
          </Typography>
          <IconButton
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            color="gray"
            size="sm"
            variant="text"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-4 w-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
        </DialogHeader>
        <DialogBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="overflow-y-auto max-h-[80vh] pt-4"
        >
          <div className="flex items-center flex-col md:flex-row gap-4 mt-6">
            <div className="w-full">
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="small"
                color="blue-gray"
                className="mb-1 font-medium"
              >
                Title
              </Typography>
              <Input
                crossOrigin={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                color="gray"
                placeholder="Title"
                labelProps={{
                  className: "hidden",
                }}
                className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                name="title"
                value={announcement.title}
                onChange={handleInputChange}
              />
            </div>
            <div className="w-full">
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="small"
                color="blue-gray"
                className="mb-1 font-medium"
              >
                Type
              </Typography>
              <Select
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                labelProps={{
                  className: "hidden",
                }}
                className="border-t-blue-gray-200 aria-[expanded=true]:border-t-primary"
                onChange={(value) => handleInputChange(value as string)}
                value={announcement.type}
              >
                <Option value="Announcement">Announcement</Option>
                <Option value="Reminder">Reminder</Option>
              </Select>
            </div>
          </div>
          <div>
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="small"
              color="blue-gray"
              className="mb-1 font-medium"
            >
              Description
            </Typography>
            <Textarea
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              rows={6}
              placeholder="Description"
              labelProps={{
                className: "hidden",
              }}
              className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
              name="description"
              value={announcement.description}
              onChange={handleInputChange}
            />
          </div>
          <label
            htmlFor="upload"
            className="grid place-items-center py-10 rounded-lg border border-dashed border-gray-300 mt-6"
          >
            <input
              type="file"
              id="upload"
              multiple
              accept="image/*,.pdf"
              onChange={handleFileChange}
              className="hidden"
            />

            <ArrowUpTrayIcon
              className="h-8 w-8 text-gray-900"
              strokeWidth={2}
            />
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              color="blue-gray"
              className="mb-1 font-bold"
            >
              Drag and Drop or Choose a Local File
            </Typography>
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="small"
              className="font-normal text-gray-600"
            >
              Supported formats: .pdf, .png, .jpg, .svg
            </Typography>
          </label>
          {(existingFiles.length > 0 || files.length > 0) && (
            <div className="mt-4">
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="small"
                color="blue-gray"
                className="mb-2 font-medium"
              >
                Files:
              </Typography>
              {existingFiles.map((file, index) => (
                <div
                  key={`existing-${index}`}
                  className="flex items-center justify-between mb-2"
                >
                  <Typography
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    variant="small"
                    className="text-gray-600"
                  >
                    {file.name || `Existing File ${index + 1}`}
                  </Typography>
                  <button
                    onClick={() => handleRemoveExistingFile(index)}
                    className="text-red-500 text-sm"
                  >
                    <TrashIcon className="h-4 w-4 " strokeWidth={1} />
                  </button>
                </div>
              ))}
              {files.map((file, index) => (
                <div
                  key={`new-${index}`}
                  className="flex items-center justify-between mb-2"
                >
                  <Typography
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    variant="small"
                    className="text-gray-600"
                  >
                    {file.name || `New File ${index + 1}`}
                  </Typography>
                  <button
                    onClick={() => handleRemoveNewFile(index)}
                    className="text-red-500 text-sm"
                  >
                    <TrashIcon className="h-4 w-4 " strokeWidth={1} />
                  </button>
                </div>
              ))}
            </div>
          )}
        </DialogBody>
        <DialogFooter
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="gap-2"
        >
          <Button
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default Announcement;
