import { ThreeColsSection } from "../../../Components/ThreeColsSection";
import { TwoColsSection } from "../../../Components/TwoColsSection";
import data from "./data.json";
import { PricingTables } from "../../../Components/PricingTables";
import { Faq } from "../../../Components/Faq";
import { Banner } from "../../../Components/Banner";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const LandingPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem("user");
    const facilityData = localStorage.getItem("facility");
    if (userData && facilityData) {
      const facility = JSON.parse(facilityData);
      navigate(`/facility/${facility._id}/dashboard`);
    }
  }, []);
  return (
    <div className="flex flex-col gap-36 px-8 py-16">
      <TwoColsSection
        color={data.primaryColor}
        colorLengthOnHeading={2}
        subHeading={data.components.twoColsSection1.subHeading}
        heading={data.components.twoColsSection1.heading}
        description={data.components.twoColsSection1.description}
        buttonLabel={data.components.twoColsSection1.buttonLabel}
        imageSrc={data.components.twoColsSection1.imageSrc}
        inputPlaceHolder={data.components.twoColsSection1.inputPlaceHolder}
      />
      <ThreeColsSection
        heading={data.components.threeColsSection1.heading}
        color={data.primaryColor}
        cards={data.components.threeColsSection1.cards}
      />
      {/* <TwoColsSection
        color={data.primaryColor}
        colorLengthOnHeading={2}
        subHeading={data.components.twoColsSection2.subHeading}
        heading={data.components.twoColsSection2.heading}
        description={data.components.twoColsSection2.description}
        buttonLabel={data.components.twoColsSection2.buttonLabel}
        imageSrc={data.components.twoColsSection2.imageSrc}
        reverse
      />

      <TwoColsSection
        color={data.primaryColor}
        colorLengthOnHeading={2}
        subHeading={data.components.twoColsSection3.subHeading}
        heading={data.components.twoColsSection3.heading}
        description={data.components.twoColsSection3.description}
        buttonLabel={data.components.twoColsSection3.buttonLabel}
        imageSrc={data.components.twoColsSection3.imageSrc}
      /> */}

      <PricingTables
        color={data.primaryColor}
        heading={data.components.threeColPricingTable.heading}
        cards={data.components.threeColPricingTable.cards}
        description={data.components.threeColPricingTable.description}
        subHeading={data.components.threeColPricingTable.subHeading}
      />
      <Faq
        color={data.primaryColor}
        heading={data.components.faqSection.heading}
        description={data.components.faqSection.description}
        subHeading={data.components.faqSection.subHeading}
      />
      <Banner
        heading={data.components.banner.heading}
        buttonLabel1={data.components.banner.buttonLabel1}
        buttonLabel2={data.components.banner.buttonLabel2}
      />
    </div>
  );
};
