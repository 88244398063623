import OverViewWidget from "./OverViewWidget";
import ActivitiesWidget from "./ActivitiesWidget";
import TabsWidget from "./TabsWidget";
import Greeting from "./Greeting";
import { useEffect, useState } from "react";
import axios from "axios";
import { urlServer } from "../../../Configuration/heroku";
import { Button } from "@material-tailwind/react";
import { SendEmailDialog } from "../../../Dialogs/SendEmail";
import { useParams } from "react-router-dom";

interface IUnit {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
}

function DashboardPage() {
  const [isSendEmailDialogOpen, setIsSendEmailDialogOpen] = useState(false);
  const [units, setUnits] = useState<IUnit[]>([]);
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });
  let { facilityId } = useParams();

  const fetchUnits = async () => {
    try {
      const response = await axios.get(`${urlServer}/api/units/${facilityId}`);
      const data = response.data;
      setUnits(data.units || []);
    } catch (error) {
      console.error("Error fetching unit data:", error);
    }
  };

  useEffect(() => {
    fetchUnits();
  }, []);
  return (
    <section className="px-8 lg:px-16 py-10">
      <div className="flex items-center justify-between">
        <Greeting name={"Stark"} />
        <Button
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="mt-2"
          onClick={() => setIsSendEmailDialogOpen(true)}
        >
          Send Email
        </Button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
        <div className="md:col-span-2">
          <OverViewWidget />
          <TabsWidget />
        </div>
        <div className="md:col-span-1">
          <ActivitiesWidget units={units} />
        </div>
      </div>
      <SendEmailDialog
        isOpen={isSendEmailDialogOpen}
        onClose={() => setIsSendEmailDialogOpen(false)}
      />
    </section>
  );
}

export { DashboardPage };
