import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import translationData from "../Data/translations.json";

interface LanguageContextType {
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  translate: (key: string) => string;
}

const LanguageContext = createContext<LanguageContextType>({
  language: "en",
  setLanguage: () => {},
  translate: (key: string) => key,
});

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [language, setLanguage] = useState<string>(
    localStorage.getItem("language") || "en"
  );

  // Function to translate text based on the current language
  const translate = (key: string) => {
    const languageData =
      (translationData as { [key: string]: any })[language] || {};

    if (!languageData || !languageData.hasOwnProperty(key)) {
      return key; // Return the key if translation is missing
    }
    return languageData[key] || key;
  };

  // Update localStorage when language changes
  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage, translate }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = (): LanguageContextType =>
  useContext(LanguageContext);
