import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  IconButton,
  Typography,
  DialogFooter,
  Input,
  Spinner,
} from "@material-tailwind/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { format } from "date-fns";
import { urlServer } from "../../../Configuration/heroku";

interface Event {
  _id?: string;
  title: string;
  start: string;
  end: string | null;
  eventType: number;
}

interface EventModalProps {
  open: boolean;
  onClose: () => void;
  date: string | null;
  event: Event | null;
  onSave: (
    title: string,
    start: string | null,
    end: string | null,
    eventType: number
  ) => void;
  onDelete: () => void;
}

const EventModal: React.FC<EventModalProps> = ({
  open,
  onClose,
  date,
  event,
  onSave,
  onDelete,
}) => {
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const [form, setForm] = useState({
    title: event?.title || "",
    startDate: event ? new Date(event.start) : new Date(),
    endDate: event?.end ? new Date(event.end) : null,
    facilityId: user?.facilities[0]?.facilityId || "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (event) {
      setForm({
        title: event.title,
        startDate: new Date(event.start),
        endDate: event.end ? new Date(event.end) : null,
        facilityId: user?.facilities[0]?.facilityId || "",
      });
    } else {
      setForm({
        title: "",
        startDate: date ? new Date(date) : new Date(),
        endDate: null,
        facilityId: user?.facilities[0]?.facilityId || "",
      });
    }
  }, [event, date, user]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleStartDateChange = (date: Date | null) => {
    setForm((prevForm) => ({
      ...prevForm,
      startDate: date || new Date(),
    }));
  };

  const handleEndDateChange = (date: Date | null) => {
    setForm((prevForm) => ({
      ...prevForm,
      endDate: date,
    }));
  };

  const handleSave = async () => {
    setLoading(true);
    setError("");
    try {
      if (event) {
        await axios.put(
          `${urlServer}/api/${user?.facilities[0]?.facilityId}/event/${event._id}`,
          {
            title: form.title,
            startDate: form.startDate ? form.startDate.toISOString() : null,
            endDate: form.endDate ? form.endDate.toISOString() : null,
          }
        );
      } else {
        await axios.post(
          `${urlServer}/api/${user?.facilities[0]?.facilityId}/event`,
          {
            title: form.title,
            startDate: form.startDate ? form.startDate.toISOString() : null,
            endDate: form.endDate ? form.endDate.toISOString() : null,
          }
        );
      }
      setLoading(false);
      onSave(
        form.title,
        form.startDate.toISOString(),
        form.endDate?.toISOString() || null,
        event?.eventType || 1
      );
      onClose();
    } catch (error) {
      setLoading(false);
      setError("Failed to save the event. Please try again.");
      console.error(error);
    }
  };

  const handleDelete = () => {
    onDelete();
    onClose();
  };

  return (
    <Dialog
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      open={open}
      handler={onClose}
      className="p-4 max-w-md mx-auto"
    >
      <DialogHeader
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="flex justify-between pb-2"
      >
        <Typography
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          color="blue-gray"
          className="text-lg font-bold"
        >
          {event ? "Edit Event" : "Create Event"}
        </Typography>
        <IconButton
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          color="gray"
          size="sm"
          variant="text"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            className="h-4 w-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </DialogHeader>
      <DialogBody
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="space-y-4"
      >
        {error && (
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            color="red"
            className="text-sm"
          >
            {error}
          </Typography>
        )}
        <div className="flex flex-col">
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="small"
            color="blue-gray"
            className="mb-1"
          >
            Event Title
          </Typography>
          <Input
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            name="title"
            value={form.title}
            onChange={handleChange}
            crossOrigin={undefined}
          />
        </div>

        <div className="flex flex-col">
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="small"
            color="blue-gray"
            className="mb-1"
          >
            Select Start Date & Time
          </Typography>
          <DatePicker
            selected={form.startDate}
            onChange={handleStartDateChange}
            showTimeSelect
            dateFormat="yyyy-MM-dd hh:mm aa"
            className="w-full h-10"
            customInput={
              <Input
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                name="startDate"
                crossOrigin={undefined}
                value={
                  form.startDate
                    ? format(form.startDate, "yyyy-MM-dd hh:mm aa")
                    : ""
                }
                readOnly
              />
            }
          />
        </div>

        <div className="flex flex-col">
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="small"
            color="blue-gray"
            className="mb-1"
          >
            Select End Date & Time
          </Typography>
          <DatePicker
            selected={form.endDate}
            onChange={handleEndDateChange}
            showTimeSelect
            dateFormat="yyyy-MM-dd hh:mm aa"
            className="w-full h-10"
            customInput={
              <Input
                crossOrigin={undefined}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                name="endDate"
                value={
                  form.endDate
                    ? format(form.endDate, "yyyy-MM-dd hh:mm aa")
                    : ""
                }
                readOnly
              />
            }
          />
        </div>
      </DialogBody>
      <DialogFooter
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="space-x-2"
      >
        <Button
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          variant="outlined"
          onClick={onClose}
          disabled={loading}
        >
          Cancel
        </Button>
        {event && (
          <Button
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="outlined"
            color="red"
            onClick={handleDelete}
            disabled={loading}
          >
            Delete
          </Button>
        )}
        <Button
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          onClick={handleSave}
          disabled={loading}
        >
          {loading ? (
            <Spinner
              crossOrigin={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            />
          ) : (
            "Save Changes"
          )}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default EventModal;
