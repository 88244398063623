import React from "react";
import {
  Typography,
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";

import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";
interface PaginationProps {
  currentPage: number;
  totalPages: number;
  rowsPerPage: number;
  setCurrentPage: (page: number) => void;
  setRowsPerPage: (rows: number) => void;
  elementsPerPageOptions: number[];
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  rowsPerPage,
  setCurrentPage,
  setRowsPerPage,
  elementsPerPageOptions,
}) => {
  const prev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const next = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <section className="container mx-auto py-10 px-4">
      <div className="flex items-end gap-y-8 justify-between">
        <div className="flex flex-col md:flex-row md:items-center gap-3">
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="small"
            className="text-gray-600 font-bold"
          >
            Results:{" "}
            <span className="text-gray-900">{totalPages * rowsPerPage}</span>
          </Typography>
          <div className="flex flex-col md:flex-row md:items-center gap-2">
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="small"
              className="text-gray-600 font-bold"
            >
              Rows per page:
            </Typography>
            <Menu>
              <MenuHandler>
                <Button
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="outlined"
                  className="flex items-center gap-2 !border-gray-300"
                >
                  {rowsPerPage}
                  <ChevronDownIcon strokeWidth={3} className="w-3 h-3" />
                </Button>
              </MenuHandler>
              <MenuList
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                {elementsPerPageOptions
                  .filter((option) => option !== rowsPerPage)
                  .map((option) => (
                    <MenuItem
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      key={option}
                      onClick={() => setRowsPerPage(option)}
                    >
                      {option}
                    </MenuItem>
                  ))}
              </MenuList>
            </Menu>
          </div>
        </div>
        <div className="flex gap-4 items-center">
          <IconButton
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="outlined"
            onClick={prev}
            disabled={currentPage === 1}
            className="border-gray-300"
          >
            <ChevronLeftIcon strokeWidth={3} className="h-3 w-3" />
          </IconButton>
          <Typography
            variant="small"
            className="font-bold text-gray-600"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            Page <strong className="text-gray-900">{currentPage}</strong> of{" "}
            <strong className="text-gray-900">{totalPages}</strong>
          </Typography>
          <IconButton
            variant="outlined"
            onClick={next}
            disabled={currentPage === totalPages}
            className="border-gray-300"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <ChevronRightIcon strokeWidth={3} className="h-3 w-3" />
          </IconButton>
        </div>
      </div>
    </section>
  );
};

export default Pagination;
