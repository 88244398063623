import { Card, Typography, Avatar } from "@material-tailwind/react";
import moment from "moment";
import React, { useEffect, useState } from "react";

interface IUnit {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
}
interface ActivitiesProp {
  units: IUnit[];
}
const UpcomingEvents: React.FC<ActivitiesProp> = ({ units }) => {
  const [birthdays, setBirthdays] = useState<IUnit[]>([]);
  const [visibleCount, setVisibleCount] = useState(4);

  useEffect(() => {
    const currentMonth = moment().month();

    const filteredBirthdays = units.filter((item) => {
      const dateOfBirth = moment(item.dateOfBirth);
      return dateOfBirth.month() === currentMonth;
    });

    setBirthdays(filteredBirthdays);
  }, [units]);

  const handleLoadMore = () => {
    setVisibleCount((prev) => prev + 4);
  };

  return (
    <div className="relative">
      <div className="overflow-y-auto max-h-96 px-2">
        {birthdays.slice(0, visibleCount).map((event, index) => (
          <Card
            key={index}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            className="w-full max-w-full my-4 p-4 border rounded-lg shadow-md hover:shadow-lg transition-shadow"
          >
            <div className="flex items-center space-x-4">
              <Avatar
                src={`https://ui-avatars.com/api/?name=${event.firstName}+${event.lastName}&background=random`}
                alt={`${event.firstName} ${event.lastName}`}
                variant="circular"
                size="md"
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                className="border border-gray-300"
              />
              <div className="flex flex-col flex-grow">
                <Typography
                  variant="h6"
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  className="text-md font-semibold text-gray-800"
                >
                  {event.firstName} {event.lastName}
                </Typography>
                <Typography
                  variant="small"
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  className="text-gray-600 text-sm"
                >
                  {moment().diff(event.dateOfBirth, "years")} years old
                </Typography>
              </div>
              <div className="text-right">
                <Typography
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="small"
                  className="text-sm font-medium text-gray-600"
                >
                  {moment(event.dateOfBirth).format("ddd, MMM DD, YYYY")}
                </Typography>
              </div>
            </div>
          </Card>
        ))}
      </div>
      {birthdays.length > visibleCount && (
        <div className="flex justify-center mt-4">
          <span
            onClick={handleLoadMore}
            className="px-4 py-2 text-blue-500 text-sm font-medium hover:underline cursor-pointer"
          >
            Load More
          </span>
        </div>
      )}
    </div>
  );
};

export default UpcomingEvents;
