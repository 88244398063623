import { Avatar, Card, Typography } from "@material-tailwind/react";
import { formatTimestampForRoomElement } from "../../Helpers/chatHelper";
import { Timestamp } from "firebase/firestore";
import { FunctionComponent } from "react";

interface RoomElementProps {
  name: string;
  avatar: string;
  lastMessage: string;
  time: Timestamp;
  online: boolean;
  unread: number;
  onClick: () => void;
}

export const RoomElement: FunctionComponent<RoomElementProps> = (props) => {
  return (
    <Card
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      className={`w-full rounded-lg p-4 bg-white cursor-pointer`}
      onClick={() => props.onClick()}
    >
      <div className="flex justify-between items-center">
        <div className="flex space-x-4">
          {/* <StyledBadge online={props.online}> */}
          <Avatar
            className="w-10 h-10"
            style={{ minWidth: "2.5rem" }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            src={props.avatar}
            alt={props.name}
          />
          {/* </StyledBadge> */}
          <div className="hidden md:flex flex-col space-y-1">
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="small"
              className="font-semibold"
            >
              {props.name}
            </Typography>
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="small"
              className="truncate text-gray-500"
              style={{ maxWidth: "8rem" }}
            >
              {props.lastMessage}
            </Typography>
          </div>
        </div>
        {/* <div className="hidden md:flex flex-col items-center justify-between h-12">
          <div>
            <Typography
              className="text-xs"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="small"
            >
              {formatTimestampForRoomElement(props.time)}
            </Typography>
          </div>
          <div
            className="rounded-full text-white px-1 text-sm"
            style={{ background: "#2196F3", marginRight: "1px" }}
          >
            {props.unread > 99 ? "+99" : props.unread}
          </div>
        </div> */}
      </div>
    </Card>
  );
};
