import React, { useEffect, useState } from "react";
import axios from "axios";
import { urlServer } from "../../Configuration/heroku";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Chip } from "@material-tailwind/react";
import { IUnit } from "../../Data/unit";
import { IUser } from "../../Data/user";

interface Invoice {
  id: string;
  unit: IUnit;
  user: IUser;
  customer_email: string;
  timestamp: string;
  type: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
}

const RoutesTable = ({ selectedUser }: { selectedUser: string }) => {
  const { facilityId } = useParams();
  const [routes, setRoutes] = useState<Invoice[]>([]);
  const [filteredRoutes, setFilteredRoutes] = useState<Invoice[]>([]);

  useEffect(() => {
    const fetchRoutes = async () => {
      try {
        const response = await axios.get(
          `${urlServer}/api/transportation/${facilityId}`
        );
        setRoutes(response.data);
      } catch (error) {
        console.error("Error fetching routes:", error);
      }
    };

    fetchRoutes();
  }, [facilityId]);

  useEffect(() => {
    if (selectedUser) {
      const filtered = routes.filter(
        (route) => route.user._id === selectedUser
      );
      setFilteredRoutes(filtered);
    } else {
      setFilteredRoutes(routes);
    }
  }, [selectedUser, routes]);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr className="w-full bg-gray-100 border-b border-gray-200">
            <th className="py-2 px-4 text-left text-gray-600">Client</th>
            <th className="py-2 px-4 text-left text-gray-600">Staff</th>
            <th className="py-2 px-4 text-left text-gray-600">Location</th>
            <th className="py-2 px-4 text-left text-gray-600">Time Stamp</th>
            <th className="py-2 px-4 text-left text-gray-600">Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredRoutes.map((invoice) => (
            <tr key={invoice.id} className="border-b border-gray-200">
              <td className="py-2 px-4">
                {invoice.unit.firstName} {invoice.unit.lastName}
              </td>
              <td className="py-2 px-4">
                {invoice.user.firstName} {invoice.user.lastName}
              </td>
              <td className="py-2 px-4">
                {[
                  invoice.address,
                  invoice.city,
                  invoice.state,
                  invoice.zipCode,
                ].join(", ")}
              </td>
              <td className="py-2 px-4">
                {moment(invoice.timestamp).format("MMM Do YYYY, h:mm A")}
              </td>
              <td className="py-2 px-4">
                <Chip
                  className="w-20 p-2 h-full text-center"
                  color={invoice.type === "pickup" ? "green" : "red"}
                  value={invoice.type === "pickup" ? "Pick Up" : "Drop off"}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RoutesTable;
