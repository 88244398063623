import {
  Breadcrumbs,
  Card,
  CardBody,
  Avatar,
  Typography,
  Tabs,
  Tab,
  TabsHeader,
  TabsBody,
  TabPanel,
} from "@material-tailwind/react";
import React, { useState, useEffect } from "react";
import {
  Square3Stack3DIcon,
  UserCircleIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/solid";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FacilityProfile } from "../../Components/FacilityProfile";
import { urlServer } from "../../Configuration/heroku";

interface Facility {
  _id: string;
  name: string;
  email: string;
  fax: string;
  phone: string;
  address: string;
  zipCode: string;
  city: string;
  state: string;
  country: string;
}

export const FacilityPage = () => {
  const { id } = useParams<{ id: string }>();
  const [selectedTab, setSelectedTab] = useState("profile");
  const [facility, setFacility] = useState<Facility | null>(null);

  useEffect(() => {
    const fetchFacility = async () => {
      try {
        const response = await axios.get(`${urlServer}/api/facility/${id}`);
        setFacility(response.data);
      } catch (error) {
        console.error("Error fetching facility:", error);
      }
    };

    fetchFacility();
  }, [id]);

  const data = [
    {
      label: "Feed",
      value: "feed",
      icon: Square3Stack3DIcon,
      desc: `It really matters and then like it really doesn't matter.
          What matters is the people who are sparked by it. And the people
          who are like offended by it, it doesn't matter.`,
    },
    {
      label: "Profile",
      value: "profile",
      icon: UserCircleIcon,
      Component: facility ? <FacilityProfile facility={facility} /> : null,
    },
    {
      label: "Settings",
      value: "settings",
      icon: Cog6ToothIcon,
      desc: `We're not always in the position that we want to be at.
          We're constantly growing. We're constantly making mistakes. We're
          constantly trying to express ourselves and actualize our dreams.`,
      Component: null,
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <a href="/" className="opacity-60">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
          </svg>
        </a>
        <a href="/facilities" className="opacity-60">
          Facilities
        </a>
        <a>{facility?.name}</a>
      </Breadcrumbs>
      <Card
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <CardBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <div className="flex items-center">
            <Avatar
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              src="https://picsum.photos/id/1/200"
              alt="User"
              size="lg"
            />
            <div className="ml-4">
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="h6"
              >
                {facility ? facility.name : "Loading..."}
              </Typography>
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                color="blue-gray"
              >
                {facility ? facility.email : ""}
              </Typography>
            </div>
          </div>
        </CardBody>
      </Card>
      <Tabs
        value={selectedTab}
        onChange={(value: string) => setSelectedTab(value)}
      >
        <TabsHeader
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          {data.map(({ label, value, icon }) => (
            <Tab
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              key={value}
              value={value}
            >
              <div className="flex items-center gap-2">
                {React.createElement(icon, { className: "w-5 h-5" })}
                {label}
              </div>
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          animate={{
            initial: { y: 250 },
            mount: { y: 0 },
            unmount: { y: 250 },
          }}
        >
          {data.map(({ value, desc, Component }) => (
            <TabPanel key={value} value={value} className="px-0">
              {Component ? Component : desc}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
  );
};
