import { Typography, Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const PageNotFoundPage = () => {
  const navigate = useNavigate();
  const [facilityId, setFacilityId] = useState<string | null>(null);

  useEffect(() => {
    const facilityData = localStorage.getItem("facility");
    if (facilityData) {
      const facilityId = JSON.parse(facilityData)._id;
      setFacilityId(facilityId);
    }
  }, []);

  return (
    <section>
      <div className="relative min-h-screen w-full">
        <div className="grid min-h-screen px-8">
          <div className="container relative z-10 my-auto mx-auto grid place-items-center text-center">
            <Typography
              variant="h1"
              color="blue-gray"
              className="text-4xl !leading-snug lg:text-5xl"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              404 😢
            </Typography>
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="h1"
              color="blue-gray"
              className="mt-6 text-4xl !leading-snug lg:text-4xl"
            >
              Lost in the Digital Wilderness
            </Typography>
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="lead"
              color="gray"
              className="mt-4 mb-6 w-full md:max-w-full lg:mb-12 lg:max-w-3xl"
            >
              You&apos;ve ventured into uncharted digital territory. The page
              you seek has eluded us. Let&apos;s guide you back to familiar
              paths.
            </Typography>
            <Button
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              color="gray"
              className="w-full px-4 md:w-[9rem]"
              onClick={() => navigate(`/facility/${facilityId}/dashboard`)}
            >
              back to home
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
