import React from "react";
import {
  Typography,
  Card,
  CardBody,
  CardFooter,
  IconButton,
  Button,
  Chip,
} from "@material-tailwind/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { UserIcon } from "@heroicons/react/24/solid";
import { useNavigate, useParams } from "react-router-dom";
import { IUnit } from "../Data/unit";
import AssignUsers from "../Dialogs/AssignUsers";
import { IUser } from "../Data/user";

const TABLE_HEAD = ["Name", "Email", "Status"];

interface UnitsTableProps {
  units: IUnit[];
  user: any;
  users: IUser[];
  refresh: () => void;
}
export const UsersTable: React.FC<UnitsTableProps> = ({
  units,
  user,
  users,
  refresh,
}) => {
  let navigate = useNavigate();
  let { facilityId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [isSelectDialogOpen, setIsSelectDialogOpen] = useState(false);

  const next = () => {
    if (currentPage === 10) return;
    setCurrentPage(currentPage + 1);
  };

  const prev = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  };
  return (
    <section>
      <Card
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="h-full w-full"
      >
        <div className="flex justify-between items-center mx-4">
          {" "}
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="h6"
            className="m-3 flex items-center gap-1"
          >
            <UserIcon className="!w-5 !h-5" /> Users
          </Typography>
          <Button
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            className="mt-2"
            onClick={() => setIsSelectDialogOpen((cur) => !cur)}
          >
            {" "}
            Add Users
          </Button>
        </div>
        <CardBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="!p-0"
        >
          <table className="w-full min-w-max table-auto text-center">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-b border-gray-300 !p-6 text-center"
                  >
                    <Typography
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      color="blue-gray"
                      variant="small"
                      className="!font-bold"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {users && users.length > 0 ? (
                users.map((users, index) => {
                  const { _id, firstName, lastName, email, status } = users;
                  const classes = "!p-4 border-b border-gray-300 text-center";
                  return (
                    <tr
                      key={_id}
                      onClick={() => {
                        navigate(`/facility/${facilityId}/user/${_id}`, {
                          state: { users },
                        });
                      }}
                      className="cursor-pointer hover:bg-gray-100"
                    >
                      <td className={classes}>
                        <Typography
                          placeholder={undefined}
                          onPointerEnterCapture={undefined}
                          onPointerLeaveCapture={undefined}
                          variant="small"
                          color="blue-gray"
                          className="!font-normal"
                        >
                          {firstName} {lastName}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          placeholder={undefined}
                          onPointerEnterCapture={undefined}
                          onPointerLeaveCapture={undefined}
                          variant="small"
                          color="blue-gray"
                          className="!font-normal"
                        >
                          {email}
                        </Typography>
                      </td>

                      <td
                        className={
                          classes + " flex justify-center items-center"
                        }
                      >
                        <Typography
                          placeholder={undefined}
                          onPointerEnterCapture={undefined}
                          onPointerLeaveCapture={undefined}
                          variant="small"
                          color="blue-gray"
                          className="!font-normal"
                        >
                          <Chip
                            className="w-20"
                            color={status === "active" ? "green" : "red"}
                            value={status}
                          />
                        </Typography>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={TABLE_HEAD.length} className="p-4 text-center">
                    <Typography
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      variant="small"
                      color="blue-gray"
                      className="!font-normal"
                    >
                      No Users found.
                    </Typography>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </CardBody>
        <CardFooter
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="flex justify-between items-center"
        >
          <div className="flex gap-4 items-center ml-auto">
            <IconButton
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="outlined"
              onClick={prev}
              disabled={currentPage === 1}
              className="border-gray-300"
            >
              <ChevronLeftIcon strokeWidth={3} className="h-3 w-3" />
            </IconButton>
            <Typography
              variant="small"
              className="font-bold text-gray-600"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              Page <strong className="text-gray-900">{currentPage}</strong> of{" "}
              <strong className="text-gray-900">{totalPages}</strong>
            </Typography>
            <IconButton
              variant="outlined"
              onClick={next}
              disabled={currentPage === totalPages}
              className="border-gray-300"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <ChevronRightIcon strokeWidth={3} className="h-3 w-3" />
            </IconButton>
          </div>
        </CardFooter>
      </Card>
      <AssignUsers
        open={isSelectDialogOpen}
        onClose={() => {
          setIsSelectDialogOpen(false);
          refresh();
        }}
        users={users}
      />
    </section>
  );
};
